import { useEffect, useState } from "react";
import { Row, Col, Nav, Card, Tab } from "react-bootstrap";
import PageTitle from "../../../components/PageTitle";
import TabUsers from "./TabUser/TabUsers";
import { useLocation, useParams } from "react-router-dom";
import WebService from "../../../Services/WebService";
import TabEncryption from "./TabEncryption/TabEncryption";
import TabIntegration from "./TabIntegration/TabIntegration";
import TableLoader from "../../Common/TableLoader/TableLoader";
import TabSubscription from "./TabSubscription/TabSubscription";
import TabFTP from "./TabFTP/TabFTP";
import TabInvoice from "./TabInvoice/InvoiceHistory"
import { useDispatch, useSelector } from "react-redux";
import TabDomain from "./TabDomain/TabDomain";
import { AccessControl } from "../../Common/AccessControl/AccessControl";
import TabGeneral from "./TabGeneral/TabGeneral";
import TabFeatures from "./TabFeatures/TabFeatures";
import { setOtherServiceOn } from "../../../Redux/actions";

const ViewClientAdmin = () => {
  const state = useLocation();
  const isOtherServiceOn = useSelector((state: any) => state?.profile?.setOtherServiceOn);
  const dispatch = useDispatch();
  const [client, setClient] = useState<any>(null);
  let params = useParams();
  const [clientInfo, setClientInfo] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState("");
  const [loader, setLoader] = useState(false);
  const [activeKey, setActiveKey] = useState(state.state?.from === "DASHBOARD" ? "TabUsers" : "TabGeneral");
  const [tab, setTab] = useState("");
  const [currentSubscriptionList, setCurrentSubscriptionList] = useState<any>([]);


  useEffect(() => {
    if (params.id) {
      getClientDetails(params.id);
      getClientInfo(params.id);
    }
  }, [params.id]);

  const getClientDetails = (id: any) => {
    setSubscriptionStatus("");
    return WebService.getAPI({
      action: `client/` + id,
      body: null,
      isShowError: true,

    })
      .then((res: any) => {
        setClient(res);
        if (
          res.client_subscription &&
          res.client_subscription.status === "ACTIVE"
        ) {
          setSubscriptionStatus("ACTIVE");
        }
      })
      .catch((error: any) => {
        return error;
      });
  };

  const getClientInfo = (id: any) => {
    setLoader(true);
    return WebService.getAPI({
      action: `client/info/` + id,
      body: null,
      isShowError: true,
    })
      .then((res: any) => {
        if (Object.keys(res).length === 0) {
          setClientInfo(null);
        } else {
          setClientInfo(res);
          setCurrentSubscriptionList(res.user_integrations);
          if (res.user_integrations.some((item: any) => item.id === 19 && item.integrationStatus === "ON")) {
            dispatch(setOtherServiceOn(true));
          } else {
            dispatch(setOtherServiceOn(false));
          }
        }
        setLoader(false);
      })
      .catch((error: any) => {
        setLoader(false);
        return error;
      });
  };
  const handleTabSelect = (eventKey: any) => {
    setActiveKey(eventKey);
  }

  return (
    <>
      <div className="page-contetn page-flagging">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <PageTitle Title="View Client" subTitle={client && client.name} />
        </div>
        {!loader ? (
          <Card className="content-card" style={{ minHeight: "300px" }}>
            <Tab.Container id="left-tabs-example" defaultActiveKey={activeKey} onSelect={handleTabSelect}>
              <Row>
                <Col>
                  <Nav
                    variant="pills"
                    className="tab-style-1 mb-3"
                    style={{ backgroundColor: "#f0eeee" }}
                  >
                    {clientInfo !== null && subscriptionStatus === "ACTIVE" ? (
                      <>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="TabGeneral"
                          >
                            General
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="TabInvoice"
                          >
                            Invoice
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="TabUsers"
                          >
                            Users
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="TabConnectors"
                          >
                            Connectors
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="TabEncryption">
                            Encryption
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="TabFTP">SFTP</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="TabDomain">Domains</Nav.Link>
                        </Nav.Item>
                      </>
                    ) : (
                      ""
                    )}
                  </Nav>
                </Col>
                <Col className="d-flex gap-3 justify-content-end align-items-start"></Col>
              </Row>

              <Tab.Content>
                <Tab.Pane eventKey="TabGeneral">
                  {
                    activeKey === "TabGeneral" &&
                    <TabGeneral
                      client={client}
                      clientInfo={clientInfo}
                      sendData={(data: any) => {
                        if (data === "updated") {
                          getClientInfo(params.id);
                          setActiveKey("TabGeneral");
                        }
                      }}
                    />
                  }
                </Tab.Pane>
                <Tab.Pane eventKey="TabInvoice">
                  {
                    activeKey === "TabInvoice" && <TabInvoice clientId={params.id} />
                  }
                </Tab.Pane>
                <Tab.Pane eventKey="TabUsers">
                  {
                    activeKey === "TabUsers" && <TabUsers client={client} />
                  }
                </Tab.Pane>
                <Tab.Pane eventKey="TabConnectors">
                  {
                    activeKey === "TabConnectors" &&
                    <TabIntegration
                      client={client}
                      tab={tab}
                      currentSubscriptionList={currentSubscriptionList}
                    />
                  }
                </Tab.Pane>
                <Tab.Pane eventKey="TabEncryption">
                  {
                    activeKey === "TabEncryption" &&
                    <TabEncryption client={client} />
                  }
                </Tab.Pane>
                <Tab.Pane eventKey="TabFTP">
                  {
                    activeKey === "TabFTP" &&
                    <TabFTP clientId={params.id} />
                  }
                </Tab.Pane>
                <Tab.Pane eventKey="TabDomain">
                  {
                    activeKey === "TabDomain" &&
                    <TabDomain tab={tab} client={client} />
                  }
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card>
        ) : (
          <TableLoader customClass={"component-loader1"} />
        )}
      </div>
      <AccessControl page_type={"/client-admin-client"} />
    </>
  );
};
export default ViewClientAdmin;



