import { useEffect, useState } from "react";
import {
  Card,
  Table,
} from "react-bootstrap";
import WebService from "../../../../Services/WebService";
import { toast } from "react-toastify";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import "./subscription.scss";
import moment from "moment";
import { useLocation } from "react-router-dom";
interface propsData {
  clientId: any;
  changeInSubscription: any;
}
const TabSubscription = (props: propsData) => {
  const state = useLocation();
  const [loader, setLoader] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [subscriptionPlanList, setSubscriptionPlanList] = useState<any[]>([]);
  const [sortBy, setSortBy] = useState("status");
  const [orderBy, setOrderBy] = useState("ASC");
  const [page, setPage] = useState(1);
  const [id, setId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [clickable, setClickable] = useState("status");
  const [planData, setPlanData] = useState<any>()
  const [extraEmailCost, setExtraEmailCost] = useState<any>(0);
  const [extraSocialCost, setExtraSocialCost] = useState<any>(0);
  const [extraWebsiteCost, setExtraWebsiteCost] = useState<any>(0);
  const [extraTextCost, setExtraTextCost] = useState<any>(0);
  const [extraImessageCost, setExtraImessageCost] = useState<any>(0);
  const [extraChatCost, setExtraChatCost] = useState<any>(0);

  useEffect(() => {
    if (props.clientId) {
      getSubscriptionList(props.clientId);
    }
  }, [sortBy, orderBy, page, keyword, props.clientId]);

  useEffect(() => {
    if (state.state !== null) {
      getCurrentPlan();
    }
  }, []);

  const getSubscriptionList = (clientId: any) => {
    setLoader(true);
    const obj = {
      page: page.toString(),
      sort_by: sortBy,
      order_by: orderBy,
      client_id: clientId,
      keyword: keyword,
    };
    let qurey = new URLSearchParams(obj).toString();
    return WebService.getAPI({
      action: `subscription/list-client?` + qurey,
      body: null,
      isShowError: true,
    })
      .then((res: any) => {
        setSubscriptionPlanList(res.list);
        setTotalCount(res.count);
        setLoader(false);
      })
      .catch((error: any) => {
        setSubscriptionPlanList([]);
        toast.error(error.message);
        setLoader(false);
        return error;
      });
  };


  const getCurrentPlan = () => {
    setLoader(true);
    return WebService.getAPI({
      action: `current-subscription?client_id=${props.clientId}`,
      body: null,
      isShowError: true,
    })
      .then((res: any) => {
        setPlanData(res?.result);
        setTotalCount(res?.count)
        setLoader(false);
        setExtraEmailCost((res?.result.client.numberOfUsers - res?.result.subscription.numberOfUsers) * res?.result.subscription.costPerUser)
        setExtraSocialCost((res?.result.client.numberOfConnectors - res?.result.subscription.numberOfConnectors) * res?.result.subscription.costPerConnector)
        setExtraWebsiteCost((res?.result.client.numberOfWebsites - res?.result.subscription.numberOfWebsites) * res?.result.subscription.costPerWebsite)
        setExtraTextCost((res?.result.client.numberOfSMS - res?.result.subscription.numberOfSMS) * res?.result.subscription.costperSMS)
        setExtraImessageCost((res?.result.client.numberOfImessage - res?.result.subscription.numberOfImessage) * (res?.result.subscription.costPerImessage ? res?.result.subscription.costPerImessage : 0))
        setExtraChatCost((res?.result.client.numberOfChat - res?.result.subscription.numberOfChat) * (res?.result.subscription.costPerChat ? res?.result.subscription.costPerChat : 0))
      })
      .catch((error: any) => {
        setLoader(false);
        return error;
      });
  };
  return (
    <>
      {
        !loader && subscriptionPlanList && subscriptionPlanList.length > 0 &&
        <div className="table-card">
          <Card className="table-container-sub table-responsive" style={{ scrollbarWidth: "thin" }}>
            <Table className="table-style">
              <thead>
                <tr>
                  <th
                    className="cursor-pointer"
                    onClick={() => {
                      setSortBy("plan_name");
                      setClickable("plan_name");
                      setOrderBy(orderBy === "DESC" ? "ASC" : "DESC");
                    }}
                  >
                    Plan Name
                    {sortBy === "plan_name" && clickable === "plan_name" ? (
                      orderBy === "DESC" ? (
                        <MdOutlineKeyboardArrowDown
                          className="sort-icon cursor-pointer"
                          size={14}
                        />
                      ) : (
                        <MdOutlineKeyboardArrowUp
                          className="sort-icon cursor-pointer"
                          size={14}
                        />
                      )
                    ) : (
                      ""
                    )}
                  </th>

                  <th
                    className="cursor-pointer"
                    onClick={() => {
                      setSortBy("number_of_users");
                      setClickable("number_of_users");
                      setOrderBy(orderBy === "DESC" ? "ASC" : "DESC");
                    }}
                  >
                    # Of Emails
                    {sortBy === "number_of_users" && clickable === "number_of_users" ? (
                      orderBy === "DESC" ? (
                        <MdOutlineKeyboardArrowDown
                          className="sort-icon cursor-pointer"
                          size={14}
                        />
                      ) : (
                        <MdOutlineKeyboardArrowUp
                          className="sort-icon cursor-pointer"
                          size={14}
                        />
                      )
                    ) : (
                      ""
                    )}
                  </th>

                  <th
                    className="cursor-pointer"
                    onClick={() => {
                      setSortBy("number_of_sms");
                      setClickable("number_of_sms");
                      setOrderBy(orderBy === "DESC" ? "ASC" : "DESC");
                    }}
                  >
                    # Of Text
                    {sortBy === "number_of_sms" && clickable === "number_of_sms" ? (
                      orderBy === "DESC" ? (
                        <MdOutlineKeyboardArrowDown
                          className="sort-icon cursor-pointer"
                          size={14}
                        />
                      ) : (
                        <MdOutlineKeyboardArrowUp
                          className="sort-icon cursor-pointer"
                          size={14}
                        />
                      )
                    ) : (
                      ""
                    )}
                  </th>

                  <th
                    className="cursor-pointer"
                    onClick={() => {
                      setSortBy("number_of_websites");
                      setClickable("number_of_websites");
                      setOrderBy(orderBy === "DESC" ? "ASC" : "DESC");
                    }}
                  >
                    # Of Websites
                    {sortBy === "number_of_websites" && clickable === "number_of_websites" ? (
                      orderBy === "DESC" ? (
                        <MdOutlineKeyboardArrowDown
                          className="sort-icon cursor-pointer"
                          size={14}
                        />
                      ) : (
                        <MdOutlineKeyboardArrowUp
                          className="sort-icon cursor-pointer"
                          size={14}
                        />
                      )
                    ) : (
                      ""
                    )}
                  </th>

                  <th
                    className="cursor-pointer"
                    onClick={() => {
                      setSortBy("number_of_connectors");
                      setClickable("number_of_connectors");
                      setOrderBy(orderBy === "DESC" ? "ASC" : "DESC");
                    }}
                  >
                    # Of Social
                    {sortBy === "number_of_connectors" && clickable === "number_of_connectors" ? (
                      orderBy === "DESC" ? (
                        <MdOutlineKeyboardArrowDown
                          className="sort-icon cursor-pointer"
                          size={14}
                        />
                      ) : (
                        <MdOutlineKeyboardArrowUp
                          className="sort-icon cursor-pointer"
                          size={14}
                        />
                      )
                    ) : (
                      ""
                    )}
                  </th>

                  <th
                    className="cursor-pointer"
                    onClick={() => {
                      setSortBy("numberOfImessage");
                      setClickable("numberOfImessage");
                      setOrderBy(orderBy === "DESC" ? "ASC" : "DESC");
                    }}
                  >
                    # Of iMessage
                    {sortBy === "numberOfImessage" && clickable === "numberOfImessage" ? (
                      orderBy === "DESC" ? (
                        <MdOutlineKeyboardArrowDown
                          className="sort-icon cursor-pointer"
                          size={14}
                        />
                      ) : (
                        <MdOutlineKeyboardArrowUp
                          className="sort-icon cursor-pointer"
                          size={14}
                        />
                      )
                    ) : (
                      ""
                    )}
                  </th>

                  <th
                    className="cursor-pointer"
                    onClick={() => {
                      setSortBy("numberOfChat");
                      setClickable("numberOfChat");
                      setOrderBy(orderBy === "DESC" ? "ASC" : "DESC");
                    }}
                  >
                    # Of Chat
                    {sortBy === "numberOfChat" && clickable === "numberOfChat" ? (
                      orderBy === "DESC" ? (
                        <MdOutlineKeyboardArrowDown
                          className="sort-icon cursor-pointer"
                          size={14}
                        />
                      ) : (
                        <MdOutlineKeyboardArrowUp
                          className="sort-icon cursor-pointer"
                          size={14}
                        />
                      )
                    ) : (
                      ""
                    )}
                  </th>
                  <th className="text-center">Price</th>
                  <th className="text-center">Ai Service Cost</th>
                  <th
                    className="cursor-pointer"
                    onClick={() => {
                      setSortBy("paymentStatus");
                      setClickable("paymentStatus");
                      setOrderBy(orderBy === "DESC" ? "ASC" : "DESC");
                    }}
                  >
                    Payment Status
                    {sortBy === "paymentStatus" && clickable === "paymentStatus" ? (
                      orderBy === "DESC" ? (
                        <MdOutlineKeyboardArrowDown
                          className="sort-icon cursor-pointer"
                          size={14}
                        />
                      ) : (
                        <MdOutlineKeyboardArrowUp
                          className="sort-icon cursor-pointer"
                          size={14}
                        />
                      )
                    ) : (
                      ""
                    )}
                  </th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Start Date</th>
                  <th className="text-center">End Date</th>
                  <th className="text-center">Cancelled Date</th>
                  {/* <th className="action-col"> Action </th> */}
                </tr>
              </thead>
              <tbody>
                {
                  subscriptionPlanList.map((plan: any) => {
                    return (
                      <tr>
                        <td className="text-center">{plan.plan_name}</td>
                        <td className="text-center">
                          {plan.number_of_users}
                        </td>
                        <td className="text-center">{plan.numberOfSMS}</td>
                        <td className="text-center">
                          {plan.numberOfWebsites}
                        </td>
                        <td className="text-center">
                          {plan.numberOfConnectors}
                        </td>
                        <td className="text-center">
                          {plan.numberOfImessage}
                        </td>
                        <td className="text-center">
                          {plan.numberOfChat}
                        </td>
                        <td className="text-center text-nowrap">
                          $ {plan.price ? plan.price : 0}
                        </td>
                        <td className="text-center text-nowrap">
                          $ {plan.aiService != null ? plan.aiService : 0}
                        </td>
                        <td className="text-center text-nowrap">
                          <span
                            className={
                              plan.paymentStatus === "PENDING"
                                ? "status-badge pending"
                                : plan.paymentStatus === "PAID"
                                  ? "status-badge active"
                                  : plan.paymentStatus === "TRIAL"
                                    ? "status-badge trial"
                                    : plan.paymentStatus === "PAYMENT_DUE"
                                      ? "status-badge payment_due"
                                      : ""
                            }
                          >
                            {plan.paymentStatus.replaceAll("_", " ")}
                          </span>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <span
                            className={
                              plan.status === "ACTIVE"
                                ? "status-badge active"
                                : plan.status === "CANCELLED"
                                  ? "status-badge cancelled"
                                  : plan.status === "EXPIRED"
                                    ? "status-badge expired"
                                    : ""
                            }
                          >
                            {plan.status}
                          </span>
                        </td>
                        <td className="text-center text-nowrap">
                          {moment(plan.start_date).local().format("MM-DD-YYYY")}
                        </td>
                        <td className="text-center text-nowrap">
                          {moment(plan.end_date).local().format("MM-DD-YYYY")}
                        </td>
                        <td className="text-center">
                          {plan.cancelled_at && plan.cancelled_at !== null
                            ? moment(plan.cancelled_at)
                              .local()
                              .format("MM-DD-YYYY")
                            : "-"}
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </Table>
          </Card>
        </div >
      }
    </>
  );
};
export default TabSubscription;
