import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Col, Card, Button, Offcanvas, Row, Form, FloatingLabel, OffcanvasBody, Modal } from "react-bootstrap";
import NoDataFound from "../../../../assets/images/empty_item.svg";
import NoImage from "../../../../assets/images/noImage.png";
import fbLogo from "../../../../assets/images/socialChannels/FACEBOOK.svg";
import XLogo from "../../../../assets/images/socialChannels/twitter_icon.svg";
import instaLogo from "../../../../assets/images/socialChannels/insta-logo.svg";
import linkdnLogo from "../../../../assets/images/socialChannels/linkedin-logo.svg";
import smsLogo from "../../../../assets/images/socialChannels/android1.svg";
import webLogo from "../../../../assets/images/socialChannels/web-icon.svg";
import ZoomLogo from "../../../../assets/images/zoom-logo.svg";
import TeamsLogo from "../../../../assets/images/ms-team-icon.svg";
import YoutubeLogo from "../../../../assets/images/socialChannels/youtube-logo.svg";
import EmailLogo from "../../../../assets/images/email-archive-icon.svg";
import RingCentralLogo from "../../../../assets/images/socialChannels/Ring-central-icon.png";
import WebService from "../../../../Services/WebService";
import HelperService from "../../../../Services/HelperService";
import ConnectBlade from "./ConnectBlade";
import "./integration.scss";
import TableLoader from "../../../Common/TableLoader/TableLoader";
import { TooltipCustom } from "../../../Common/Tooltip/Tooltip";
import { useNavigate, useParams } from "react-router-dom";
import { Label } from "../../../Common/Label/Label";
import { PiCopySimpleBold, PiSealWarning, PiUploadSimple } from "react-icons/pi";
import { toast } from "react-toastify";
import Password from "../../../Common/Password/Password";
import { MdOutlineEdit, MdOutlineRemoveRedEye } from "react-icons/md";
import ImessageLogo from "../../../../assets/images/socialChannels/IMessage_logo.png";
import myRepChatLogo from "../../../../assets/images/socialChannels/MyRepChat-icon.jpeg"
import { BsEye, BsEyeSlash } from "react-icons/bs";
import AsyncSelect from "react-select/async";
import whatsappLogo from "../../../../assets/images/socialChannels/whatsapp.svg";
import slackLogo from "../../../../assets/images/socialChannels/slack.svg";
import { useSelector } from "react-redux";
import bloombergLogo from "../../../../assets/images/socialChannels/bloomberg.jpg";
import otherSmsLogo from "../../../../assets/images/socialChannels/otherSms.svg";
import stocktwitsLogo from "../../../../assets/images/socialChannels/stacktwits-icon.png";



interface PropsData {
  client: any;
  tab?: any;
  currentSubscriptionList?: any
}
const TabIntegration = (props: PropsData) => {
  // const passwordRef = useRef<any>();
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm();
  const {
    register: register1,
    handleSubmit: handleSubmit1,
    setValue: setValue1,
    formState: { errors: errors1 },
    clearErrors: clearErrors1,
    setError: setError1,
    watch: watch1,
  } = useForm();
  let watchVariable1 = watch1();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    setValue: setValue2,
    formState: { errors: errors2 },
    clearErrors: clearErrors2,
    setError: setError2,
    watch: watch2,
  } = useForm();
  let watchVariable2 = watch2();

  const {
    register: register3,
    handleSubmit: handleSubmit3,
    setValue: setValue3,
    formState: { errors: errors3 },
    clearErrors: clearErrors3,
    setError: setError3,
    watch: watch3,
  } = useForm();
  let watchVariable3 = watch3();

  const {
    register: register4,
    handleSubmit: handleSubmit4,
    setValue: setValue4,
    formState: { errors: errors4 },
    clearErrors: clearErrors4,
    setError: setError4,
    watch: watch4,
  } = useForm();
  let watchVariable4 = watch4();

  const params = useParams();
  const navigate = useNavigate();
  const [socialType, setSocialType] = useState<any>("");
  const [files, setFile] = useState<File>();
  const [fileErrorMsg, setFileErrorMsg] = useState("")
  const [errorFlag, setErrorFlag] = useState(false);
  const [action, setAction] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [showImport, setShowImport] = useState<any>(false);
  const [showConnect, setShowConnect] = useState(false);
  const [hostFlag, setHostFlag] = useState<any>(false);
  const [userNameFlag, setUsernameFlag] = useState<any>(false);
  const [selectedHost, setSelectedHost] = useState<any>();
  const [selectedUsername, setSelectedUsername] = useState<any>(
    {
      value: "",
      label: "Select user"
    }
  );
  const [usernameEditPopup, setUsernameEditPopup] = useState<any>(false);
  const handleShowConnect = () => {
    setShowConnect(true);
  };
  const [loader, setLoader] = useState(false);
  const [integrationDetailList, setIntegrationDetailsList] = useState<any[]>([]);
  const [integrationSocialDetailList, setIntegrationSocialDetailsList] = useState<any[]>([]);
  const [integrationChatDetailList, setIntegrationChatDetailsList] = useState<any[]>([]);
  const [integrationTextDetailList, setIntegrationTextDetailsList] = useState<any[]>([]);
  const [currentSubscriptionList, setCurrentSubscriptionList] = useState<any>([]);
  const [showWebsiteEditModal, setShowWebsiteEditModal] = useState<any>(false);
  const [totalConnectors, setTotalConnector] = useState<any>(0);
  const [totalSms, setTotalSms] = useState<any>(0);
  const [totalChats, setTotalChats] = useState<any>(0);
  const [totalWebsite, setTotalWebsite] = useState<any>(0);
  const [totalEmail, setTotalEmail] = useState<any>(0);
  const [totalImessage, setTotalImessage] = useState<any>(0);
  const [totalConnectorsUsed, setTotalConnectorUsed] = useState<any>(0);
  const [totalChatUsed, setTotalChatUsed] = useState<any>(0);
  const [totalSmsUsed, setTotalSmsUsed] = useState<any>(0);
  const [totalEmailUsed, setTotalEmailUsed] = useState<any>(0);
  const [totalWebsiteUsed, setTotalWebsiteUsed] = useState<any>(0);
  const [totalImessageUsed, setTotalImessageUsed] = useState<any>(0);
  const [showCredentials, setShowCredentials] = useState<any>(false);
  const [appleId, setAppleId] = useState<any>("");
  const [pass, setPass] = useState<any>("");
  const [cresLoader, setCredLoader] = useState<any>(false);
  const [uid, setUId] = useState<any>("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showClearPasswordConfirmationModel, setShowClearPasswordConfirmationModel] = useState<boolean>(false);
  const handlePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const [iMsgpasswordVisible, setIMsgPasswordVisible] = useState(false);

  const handleIMsgPasswordVisibility = () => {
    setIMsgPasswordVisible(!iMsgpasswordVisible);
  };
  useEffect(() => {
    if (props.client || props.tab === "Connect") {
      getAllIntegrationList();
    }
  }, [props.client, props.tab]);

  const deleteList = (isDelete: any) => {
    if (isDelete) {
      getAllIntegrationList();
    }
    setShowDelete(false);
  };

  const handleCallback = (data: any) => {
    setShowConnect(data);
    getAllIntegrationList();
    handleCloseConnect();
  };
  const handleCloseConnect = () => setShowConnect(false);
  const getConnectedAccounts = () => {
    setLoader(true);
    return WebService.getAPI({
      action: `connector/connected-accounts?client_id=` + props.client?.id,
      body: null,
      isShowError: true,

    })
      .then((res: any) => {
        setTotalConnector(res?.client?.numberOfConnectors);
        setTotalSms(res?.client?.numberOfSMS);
        setTotalChats(res?.client?.numberOfChat);
        setTotalEmail(res?.client?.numberOfUsers);
        setTotalWebsite(res?.client?.numberOfWebsites);
        setTotalImessage(res?.client?.numberOfImessage);

        setTotalConnectorUsed(res?.connectorCount);
        setTotalEmailUsed(res?.emailCount);
        setTotalSmsUsed(res?.smsCount);
        setTotalChatUsed(res?.chatCount);
        setTotalWebsiteUsed(res?.websiteCount);
        setTotalImessageUsed(res?.imessageCount);
        setTimeout(() => {
          setLoader(false);
        }, 2000);
      })
      .catch((error: any) => {
        setLoader(false);
        return error;
      });
  };
  const getAllIntegrationList = () => {
    setLoader(true);
    return WebService.getAPI({
      action: `connector/list?client_id=` + props.client?.id,
      body: null,
      isShowError: true,

    })
      .then((res: any) => {
        if (res.length !== 0) {
          let tempSocial: any = [];
          let tempIntegration: any = [];
          let tempText: any = [];
          let tempChat: any = [];
          res.forEach((item: any) => {
            if (
              [
                "FACEBOOK",
                "X",
                "LINKEDIN",
                "INSTAGRAM",
                "YOUTUBE",
              ].includes(item.type)
            ) {
              tempSocial.push(item);
            } else if (
              [
                "TEAMS",
                "ZOOM_CHAT",
                "SLACK",
              ].includes(item.type)
            ) {
              tempChat.push(item);
            } else if (
              [
                "SMS",
                "ZOOM_SMS",
                "MY_REPCHAT",
                "RING_CENTRAL",
                "WHATSAPP",
              ].includes(item.type)
            ) {
              tempText.push(item);
            }
            else {
              tempIntegration.push(item);
            }
          });
          setIntegrationDetailsList(tempIntegration);
          setIntegrationSocialDetailsList(tempSocial);
          setIntegrationChatDetailsList(tempChat);
          setIntegrationTextDetailsList(tempText);
          getConnectedAccounts();
        } else {
          setIntegrationDetailsList([]);
          setIntegrationSocialDetailsList([]);
          setIntegrationChatDetailsList([]);
          setIntegrationTextDetailsList([]);
        }
        setTimeout(() => {
          setLoader(false);
        }, 2000);
      })
      .catch((error: any) => {
        setLoader(false);
        return error;
      });
  };

  const handleOnChange1 = (value: any, type: any) => {
    if (type === "newPassword") {
      setValue1("newPassword", value);
      clearErrors1("newPassword");
      if (watchVariable1.newPassword === "") {
        setError1("newPassword", {
          type: "custom",
          message: "Please enter password",
        });
      }
    }
  };

  const handleOnChange = (value: any, type: any) => {
    if (type === "host") {
      watchVariable2.host = value;
      setValue2("host", value);
      clearErrors2("host");
      if (watchVariable2.host === "") {
        setError2("host", {
          type: "custom",
          message: "",
        });
      }
    } else if (type === "username") {
      watchVariable2.username = value;
      setValue2("username", value);
      clearErrors2("username");
      if (watchVariable2.username === "") {
        setError2("username", {
          type: "custom",
          message: "",
        });
      }
    } else if (type === "machinePassword") {
      watchVariable2.machinePassword = value;
      setValue2("machinePassword", value);
      clearErrors2("machinePassword");
      if (watchVariable2.machinePassword === "") {
        setError2("machinePassword", {
          type: "custom",
          message: "",
        });
      }
    }
  };


  const handleUploadClick = () => {
    toast.success("File imported successfully")
    setShowImport(false)
  };

  const getCredentials = (data: any) => {
    setShowCredentials(true);
    setCredLoader(true);
    return WebService.getAPI({
      action: `imessage/credentials?uia_id=${data.id}&client_id=${props.client.id}`,
      body: null,
      isShowError: true,

    })
      .then((res: any) => {
        setUId(data.id);
        setAppleId(res.appleId);
        if (res?.machine_details !== undefined || res?.machine_details !== null) {
          setSelectedHost({
            "id": res?.machine_details?.ip_address,
            "hostname": res?.machine_details?.hostname,
            "value": res?.machine_details?.id,
            "label": `${res?.machine_details?.hostname} (${res?.machine_details?.ip_address})`,
          });
        }
        setPass(res.password);
        setValue2("host", res?.ip_address);
        watchVariable2.host = res?.ip_address;
        clearErrors2("host");
        setValue2("username", res?.username);
        watchVariable2.username = res?.username;
        clearErrors2("username")
        setValue2("machinePassword", res?.machine_password);
        watchVariable2.machinePassword = res?.machine_password;
        clearErrors2("machinePassword")
        setCredLoader(false);

      })
      .catch((error: any) => {
        setCredLoader(false);
        return error;
      });
  }

  const handleCredentialsSave = () => {
    if (selectedHost?.id == undefined || selectedHost?.id == "") {
      setHostFlag(true);
      return false;
    }
    WebService.addLoader("credentials_save_btn");
    return WebService.postAPI({
      action: `nomachine/credential?client_id=${props.client.id}&machine_id=${selectedHost.value}&uia_id=${uid}`,
      body: null,
      isShowError: true,

    })
      .then((res: any) => {
        toast.success(res.message);
        WebService.removeLoader("credentials_save_btn");
        setShowCredentials(false);
      })
      .catch((error: any) => {
        WebService.removeLoader("credentials_save_btn");
        return error;
      });
  }

  const hostPromiseOption = (inputValue: any) =>
    new Promise<any[]>((resolve) => {
      return WebService.getAPI({
        action:
          `machine-list?page=1&keyword=` + inputValue,

      })
        .then((res: any) => {
          if (res.list.length > 0) {
            var updatedOptions = res.list
              .map((user: any, index: any) => ({
                id: user.ip_address,
                hostname: user.hostname,
                value: user.id,
                label: user.hostname + " " + "(" + user.ip_address + ")",
              }));
            resolve(updatedOptions);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          resolve([]);
          return error;
        });
    });

  const onHostSelection = (selectedOption: any) => {
    setHostFlag(false);
    setValue2("host", selectedOption.id);
    watchVariable2.host = selectedOption.id;
    setSelectedHost(selectedOption);
  };

  const usernamePromiseOption = (inputValue: any) =>
    new Promise<any[]>((resolve) => {
      return WebService.getAPI({
        action:
          `client/users?client_id=${props.client?.id}&keyword=` + inputValue,

      })
        .then((res: any) => {
          if (res.length > 0) {
            var updatedOptions = res
              .filter((user: any) => user.role_type !== "COMPLIANCE_OFFICER")
              .map((user: any, index: any) => ({
                value: user.id,
                label: user.first_name + " " + user.last_name,
              }));
            resolve(updatedOptions);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          resolve([]);
          return error;
        });
    });

  const onUserSelection = (selectedOption: any) => {
    setUsernameFlag(false);
    setValue3("user_id", selectedOption.value);
    watchVariable3.user_id = selectedOption.value;
    setSelectedUsername(selectedOption);
  };

  const handleSaveUsername = () => {
    if (selectedUsername.value === undefined || selectedUsername.value === "") {
      setUsernameFlag(true);
      return false;
    }

    WebService.addLoader("username_save_btn");
    return WebService.putAPI({
      // action: watchVariable3.integrationType === "IMESSAGE" || watchVariable3.integrationType === "SMS" || watchVariable3.integrationType === "WHATSAPP" ?
      //   `update/connector-user?user_id=${watchVariable3.user_id}&uia_id=${watchVariable3.uia_id}&account_type=${watchVariable3.accountType}` :
      //   `update/connector-user?user_id=${watchVariable3.user_id}&uia_id=${watchVariable3.uia_id}`,
      action: `update/connector-user?user_id=${watchVariable3.user_id}&uia_id=${watchVariable3.uia_id}&account_type=${watchVariable3.accountType}`,
      body: null,
      isShowError: true,

    })
      .then((res: any) => {
        toast.success(res.message);
        setUsernameEditPopup(false);
        getAllIntegrationList();
        WebService.removeLoader("username_save_btn");
        setSelectedUsername({
          value: "",
          label: "Select user"
        })

      })
      .catch((error: any) => {
        WebService.removeLoader("username_save_btn");
        return error;
      });

  }

  const handleEditWebsite = () => {
    var obj = {
      "uia_id": watchVariable4.uia_id,
      "name": watchVariable4.name
    }
    WebService.addLoader("website-edit-save");
    return WebService.putAPI({
      action: `connector/website-update?user_id=${watchVariable3.user_id}`,
      body: obj,
      isShowError: true,

    })
      .then((res: any) => {
        toast.success(res.message);
        WebService.removeLoader("website-edit-save");
        getAllIntegrationList();
        setShowWebsiteEditModal(false);
      })
      .catch((error: any) => {
        WebService.removeLoader("website-edit-save");
        toast.error(error.message);
        return error;
      });

  }

  const handleCopy = (text: any) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success("Copied");
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  const handleCredentialsError = () => {
    if (selectedHost?.id === undefined || selectedHost?.id === "") {
      setHostFlag(true);
      return false;
    }
  }

  const resetImessageCredentials = () => {
    setShowClearPasswordConfirmationModel(false);
    WebService.addLoader("reset-btn");
    return WebService.putAPI({
      action: `password-clear?client_id=${props.client.id}&uia_id=${watchVariable3.uia_id}`,
      body: null,
      isShowError: true,

    })
      .then((res: any) => {
        setPass("");
        toast.success(res.message);
        WebService.removeLoader("reset-btn");
        setShowCredentials(false)
      })
      .catch((error: any) => {
        WebService.removeLoader("reset-btn");
        setShowCredentials(false)
        return error;
      });


  }


  const logo = (channelName: any) => {
    switch (channelName) {
      case "SMS": return smsLogo;
      case "INSTAGRAM": return instaLogo;
      case "LINKEDIN": return linkdnLogo;
      case "ZOOM": return ZoomLogo;
      case "ZOOM_CHAT": return ZoomLogo;
      case "X": return XLogo;
      case "TWITTER": return XLogo;
      case "EMAIL": return EmailLogo;
      case "TEAMS": return TeamsLogo;
      case "WEBSITE": return webLogo;
      case "RING_CENTRAL": return RingCentralLogo;
      case "ZOOM_SMS": return ZoomLogo;
      case "YOUTUBE": return YoutubeLogo;
      case "FACEBOOK": return fbLogo;
      case "IMESSAGE": return ImessageLogo;
      case "MY_REPCHAT": return myRepChatLogo;
      case "SLACK": return slackLogo;
      case "WHATSAPP": return whatsappLogo;
      case "STOCKTWITS": return stocktwitsLogo;
      case "OTHER_SMS": return otherSmsLogo;
      case "BLOOMBERG": return bloombergLogo;
      default: return NoImage;
    }
  };

  return (
    <>
      <div className="page-contetn page-integration">
        {!loader ? (
          <>
            {integrationDetailList.length > 0 || integrationTextDetailList.length > 0 ||
              integrationChatDetailList.length > 0 || integrationSocialDetailList.length > 0 ? (
              <>
                <div>
                  {integrationTextDetailList &&
                    integrationTextDetailList.length > 0 &&
                    integrationTextDetailList.map((integration: any, index: any) => {
                      return (
                        <>
                          <div className="text-end mb-2 me-5 mt-2 social-buy-btn-margin1">
                            {
                              index == 0 ?
                                <span className="me-2 ">
                                  {totalSmsUsed}/{totalSms}
                                </span>
                                : ""

                            }
                          </div>
                          <Card className="inetegration-card border">
                            <div className="col-md-2 d-flex justify-content-center align-items-center">
                              <div className="d-flex gap-2 align-items-center">
                                <img
                                  src={logo(integration.type)}
                                  onError={(error: any) => {
                                    error.target.src = NoImage;
                                  }}
                                  width={30}
                                  alt=""
                                />
                                <span className=" font-medium">
                                  {integration.type === "SMS" ? "Android SMS" :
                                    integration.type === "MY_REPCHAT" ? "MyRepChat" :
                                      integration.type === "RING_CENTRAL" ? "Ring Central" :
                                        integration.type === "WHATSAPP" ? "WhatsApp" :
                                          integration.type === "ZOOM_SMS" ? "Zoom SMS" :
                                            HelperService.titleCase(integration.type.replaceAll("_", " "))
                                  }
                                </span>
                              </div>
                            </div>
                            <div className="border-start col-md-10 table-responsive" style={{ scrollbarWidth: "thin" }}>
                              <table className="integration-table">
                                <tr>
                                  <td width="16.66%">
                                    <span className="font-14 text-secondary font-bold">
                                      {integration.type === "SMS" || integration.type === "IMESSAGE"
                                        ? "Platform Type"
                                        : "Name"}
                                    </span>{" "}
                                  </td>
                                  <td width="16.66%">
                                    {" "}
                                    <span className="font-14 text-secondary font-bold ">
                                      User Name
                                    </span>
                                  </td>
                                  <td width="16.66%">
                                    {" "}
                                    <span className="font-14 text-secondary font-bold">
                                      Email
                                    </span>
                                  </td>
                                  {integration.type === "SMS" || integration.type === "IMESSAGE"
                                    || integration.type === "MY_REPCHAT" || integration.type === "RING_CENTRAL"
                                    || integration.type === "ZOOM_SMS" || integration.type === "WHATSAPP" ? (
                                    <td width="16.66%">
                                      <span className="font-14 text-secondary font-bold">
                                        Ref Id
                                      </span>
                                    </td>
                                  ) : (
                                    <td width="16.66%"></td>
                                  )}
                                  {integration.type === "IMESSAGE"
                                    || integration.type === "MY_REPCHAT" || integration.type === "WEBSITE"
                                    || integration.type === "RING_CENTRAL" || integration.type === "EMAIL"
                                    || integration.type === "ZOOM_SMS" || integration.type === "WHATSAPP" ?
                                    <td width="16.66%"></td>
                                    : ""
                                  }
                                  {
                                    integration.type === "SMS" || integration.type === "WHATSAPP" ?
                                      <td width="16.66%" className="text-center">
                                        {" "}
                                        <span className="font-14 text-secondary font-bold ">
                                          Account Type
                                        </span>
                                      </td>
                                      : ""
                                  }

                                  <td width="16.66%" className="text-center">
                                    {" "}
                                    <span className="font-14 text-secondary font-bold ">
                                      Status
                                    </span>
                                  </td>
                                  <td className="text-center text-nowrap">
                                    <span className="font-14 text-secondary font-bold ">
                                      Last Archived On
                                    </span>
                                  </td>
                                </tr>
                                {integration.list &&
                                  integration.list.length > 0 &&
                                  integration.list.map((channel: any) => {
                                    return (
                                      <tr>
                                        {integration.type === "SMS" || integration.type === "IMESSAGE" ? (
                                          <td width="16.66%">
                                            {" "}
                                            <span className="text-brand font-14">
                                              {channel.platformType}
                                            </span>
                                          </td>
                                        ) : (
                                          <td width="16.66%" className="text-nowrap">
                                            <span className="text-brand font-14 action-btns unset-center">
                                              <TooltipCustom
                                                st={
                                                  <span>
                                                    {HelperService.truncate(
                                                      HelperService.removeUnderScore(
                                                        channel.name
                                                      ),
                                                      25
                                                    )}
                                                  </span>
                                                }
                                                message={channel.name}
                                                position="top"
                                                id="c_btn"
                                              ></TooltipCustom>
                                            </span>
                                          </td>
                                        )}
                                        <td className="text-nowrap">
                                          <span className="action-btns unset-center" >
                                            {channel.tenantUser?.firstName +
                                              " " +
                                              channel.tenantUser?.lastName}
                                          </span>
                                        </td>
                                        <td>{channel.tenantUser?.email}</td>
                                        {integration.type === "WEBSITE" ? (
                                          <td width="16.66%"></td>
                                        ) : integration.type === "EMAIL" ? (
                                          <td width="16.66%"></td>
                                        ) : (
                                          <td>
                                            <TooltipCustom
                                              st={
                                                <span>
                                                  {HelperService.truncate(
                                                    channel.refId,
                                                    15
                                                  )}
                                                </span>
                                              }
                                              message={channel.refId}
                                              position="top"
                                              id="c_btn"
                                            ></TooltipCustom>
                                          </td>
                                        )}


                                        {
                                          integration.type === "IMESSAGE"
                                            ?
                                            <td
                                              className="action-btns text-center unset-center"
                                            >

                                              <Button
                                                className="action-btn btn-view btn-light font-12"
                                                title="View"
                                                onClick={() => { setHostFlag(false); getCredentials(channel); setIMsgPasswordVisible(false) }}
                                              >
                                                <MdOutlineRemoveRedEye className="icon" />
                                                View
                                              </Button>
                                            </td> :
                                            integration.type === "SMS" ?
                                              "" : <span> </span>

                                        }

                                        {
                                          integration.type === "SMS" || integration.type === "WHATSAPP" ?
                                            <td
                                              className="text-center"
                                              width="16.66%"
                                            >
                                              {
                                                <span className="status-badge active">
                                                  {channel.accountType}
                                                </span>
                                              }
                                            </td>
                                            : ""
                                        }

                                        < td
                                          className="text-center"
                                          width="16.66%"
                                        >
                                          {

                                            channel.accountStatus === "ACTIVE" ?
                                              <span className="font-14 text-success text-nowrap">Connected</span> :
                                              channel.accountStatus === "INACTIVE" ?
                                                <span className="font-14  text-reconnect text-nowrap">Disconnected </span> :
                                                channel.accountStatus === "CONFIGURATION_PENDING" ?
                                                  <span className="font-14 text-primary text-nowrap">Configuration Pending </span> :
                                                  channel.accountStatus === "CONNECTION_ERROR" ?
                                                    <span className="font-14 text-danger text-nowrap">Connection Error</span> :
                                                    channel.accountStatus === "RECONNECT" ?
                                                      <span className="font-14 text-primary text-nowrap">Reconnect</span> :
                                                      channel.accountStatus === "DELETED" ?
                                                        <span className="font-14 text-danger text-nowrap">Deleted</span> :
                                                        channel.accountStatus === "OLD_ACCOUNTS" ?
                                                          <span className="font-14 text-secondary text-nowrap">Old Account</span> :
                                                          ""

                                          }

                                        </td>
                                        <td className="text-center text-nowrap">{channel?.lastCrawlDate ? HelperService.getFormatedDateForDetail(channel?.lastCrawlDate) : "-"}</td>
                                      </tr>
                                    );
                                  })}
                              </table>
                            </div >
                          </Card >
                        </>
                      );
                    })}
                </div >
                <div>
                  {integrationChatDetailList &&
                    integrationChatDetailList.length > 0 &&
                    integrationChatDetailList.map((integration: any, index: any) => {
                      return (
                        <>
                          <div className="text-end mb-2 me-5 mt-2 social-buy-btn-margin1">
                            {
                              index == 0 ?
                                <span className="me-2 ">
                                  {totalChatUsed}/{totalChats}
                                </span>
                                : ""
                            }

                          </div>
                          <Card className="inetegration-card border">
                            <div className="col-md-2 d-flex justify-content-center align-items-center">
                              <div className="d-flex gap-2 align-items-center">
                                <img
                                  src={logo(integration.type)}
                                  onError={(error: any) => {
                                    error.target.src = NoImage;
                                  }}
                                  width={30}
                                  alt=""
                                />
                                <span className=" font-medium">
                                  {
                                    integration.type === "TEAMS" ? "Teams Chat" :
                                      integration.type === "ZOOM_Chat" ? "Zoom Chat" :
                                        HelperService.titleCase(integration.type.replaceAll("_", " "))
                                  }
                                </span>
                              </div>
                            </div>
                            <div className="border-start col-md-10 table-responsive" style={{ scrollbarWidth: "thin" }}>
                              <table className="integration-table">
                                <tr>
                                  <td width="16.66%">
                                    <span className="font-14 text-secondary font-bold">
                                      {integration.type === "SMS" || integration.type === "IMESSAGE"
                                        ? "Platform Type"
                                        : "Name"}
                                    </span>{" "}
                                  </td>
                                  <td width="16.66%">
                                    {" "}
                                    <span className="font-14 text-secondary font-bold ">
                                      User Name
                                    </span>
                                  </td>
                                  <td width="16.66%">
                                    {" "}
                                    <span className="font-14 text-secondary font-bold">
                                      Email
                                    </span>
                                  </td>
                                  {integration.type === "SMS" || integration.type === "IMESSAGE"
                                    || integration.type === "MY_REPCHAT" || integration.type === "RING_CENTRAL"
                                    || integration.type === "ZOOM_SMS" ? (
                                    <td width="16.66%">
                                      <span className="font-14 text-secondary font-bold">
                                        Ref Id
                                      </span>
                                    </td>
                                  ) : (
                                    <td width="16.66%"></td>
                                  )}
                                  {integration.type === "SMS" || integration.type === "IMESSAGE"
                                    || integration.type === "MY_REPCHAT" || integration.type === "WEBSITE"
                                    || integration.type === "RING_CENTRAL" || integration.type === "EMAIL"
                                    || integration.type === "ZOOM_SMS" ?
                                    <td width="16.66%"></td> : <td width="16.66%"></td>
                                  }

                                  {/* <td width="16.66%" className="text-center">
                                    {" "}
                                    <span className="font-14 text-secondary font-bold ">
                                      Account Type
                                    </span>
                                  </td> */}
                                  <td width="16.66%" className="text-center">
                                    {" "}
                                    <span className="font-14 text-secondary font-bold ">
                                      Account Status
                                    </span>
                                  </td>
                                  <td className="text-center text-nowrap">
                                    <span className="font-14 text-secondary font-bold ">
                                      Last Archived On
                                    </span>
                                  </td>
                                </tr>
                                {integration.list &&
                                  integration.list.length > 0 &&
                                  integration.list.map((channel: any) => {
                                    return (
                                      <tr>
                                        {integration.type === "SMS" || integration.type === "IMESSAGE" ? (
                                          <td width="16.66%">
                                            {" "}
                                            <span className="text-brand font-14">
                                              {channel.platformType}
                                            </span>
                                          </td>
                                        ) : (
                                          <td width="16.66%" className="text-nowrap">
                                            <span className="text-brand font-14 action-btns unset-center">
                                              <TooltipCustom
                                                st={
                                                  <span>
                                                    {HelperService.truncate(
                                                      HelperService.removeUnderScore(
                                                        channel.name
                                                      ),
                                                      25
                                                    )}
                                                  </span>
                                                }
                                                message={channel.name}
                                                position="top"
                                                id="c_btn"
                                              ></TooltipCustom>
                                            </span>
                                          </td>
                                        )}
                                        <td className="text-nowrap">
                                          <span className="action-btns unset-center " >
                                            {channel.tenantUser?.firstName +
                                              " " +
                                              channel.tenantUser?.lastName}
                                          </span>
                                        </td>
                                        <td>{channel.tenantUser?.email}</td>
                                        {integration.type === "WEBSITE" ? (
                                          <td width="16.66%"></td>
                                        ) : integration.type === "EMAIL" ? (
                                          <td width="16.66%"></td>
                                        ) : (
                                          <td>
                                            <TooltipCustom
                                              st={
                                                <span>
                                                  {HelperService.truncate(
                                                    channel.refId,
                                                    15
                                                  )}
                                                </span>
                                              }
                                              message={channel.refId}
                                              position="top"
                                              id="c_btn"
                                            ></TooltipCustom>
                                          </td>
                                        )}


                                        {
                                          integration.type === "IMESSAGE"
                                            ? <td
                                              className="action-btns text-center unset-center"
                                            >

                                              <Button
                                                className="action-btn btn-view btn-light font-12"
                                                title="View"
                                                onClick={() => { getCredentials(channel); setIMsgPasswordVisible(false) }}
                                              >
                                                <MdOutlineRemoveRedEye className="icon" />
                                                View
                                              </Button>
                                            </td> : <span> </span>

                                        }

                                        {/* <td
                                          className="text-center"
                                          width="16.66%"
                                        >
                                          {
                                            <span className="status-badge active">
                                              {channel.accountType}
                                            </span>
                                          }
                                        </td> */}
                                        <td
                                          className="text-center"
                                          width="16.66%"
                                        >
                                          {

                                            channel.accountStatus === "ACTIVE" ?
                                              <span className="font-14 text-success text-nowrap">Connected</span> :
                                              channel.accountStatus === "INACTIVE" ?
                                                <span className="font-14  text-reconnect text-nowrap">Disconnected </span> :
                                                channel.accountStatus === "CONFIGURATION_PENDING" ?
                                                  <span className="font-14 text-primary text-nowrap">Configuration Pending </span> :
                                                  channel.accountStatus === "CONNECTION_ERROR" ?
                                                    <span className="font-14 text-danger text-nowrap">Connection Error</span> :
                                                    channel.accountStatus === "RECONNECT" ?
                                                      <span className="font-14 text-primary text-nowrap">Reconnect</span> :
                                                      channel.accountStatus === "DELETED" ?
                                                        <span className="font-14 text-danger text-nowrap">Deleted</span> :
                                                        channel.accountStatus === "OLD_ACCOUNTS" ?
                                                          <span className="font-14 text-secondary text-nowrap">Old Account</span> :
                                                          ""

                                          }
                                        </td>
                                        <td className="text-center text-nowrap">{channel?.lastCrawlDate ? HelperService.getFormatedDateForDetail(channel?.lastCrawlDate) : "-"}</td>
                                      </tr>
                                    );
                                  })}
                              </table>
                            </div>
                          </Card>
                        </>
                      );
                    })}
                </div >
                <div>
                  {integrationDetailList &&
                    integrationDetailList.length > 0 &&
                    integrationDetailList.map((integration: any) => {
                      return (
                        <>
                          <div className="text-end mb-2 me-5 mt-2 social-buy-btn-margin1">
                            {integration.type === "SMS" ?
                              <span className="me-2 ">
                                {totalSmsUsed}/{totalSms}
                              </span>
                              : integration.type === "WEBSITE" ? <span className="me-2 ">
                                {totalWebsiteUsed}/{totalWebsite}
                              </span> : integration.type === "EMAIL" ? <span className="me-2 ">
                                {totalEmailUsed}/{totalEmail}
                              </span> : integration.type === "IMESSAGE" ? <span className="me-2 ">
                                {totalImessageUsed}/{totalImessage}
                              </span> : ""}
                          </div>
                          <Card className="inetegration-card border">
                            <div className="col-md-2 d-flex justify-content-center align-items-center">
                              <div className="d-flex gap-2 align-items-center">
                                <img
                                  src={logo(integration.type)}
                                  onError={(error: any) => {
                                    error.target.src = NoImage;
                                  }}
                                  width={30}
                                  alt=""
                                />
                                <span className=" font-medium">
                                  {
                                    integration.type == "IMESSAGE" ? "iMessage" :
                                      HelperService.titleCase(integration.type.replaceAll("_", " "))
                                  }
                                </span>
                              </div>
                            </div>
                            <div className="border-start col-md-10 table-responsive" style={{ scrollbarWidth: "thin" }}>
                              <table className="integration-table">
                                <tr>
                                  <td width="16.66%">
                                    <span className="font-14 text-secondary font-bold">
                                      {integration.type === "SMS" || integration.type === "IMESSAGE"
                                        ? "Platform Type"
                                        : "Name"}
                                    </span>{" "}
                                  </td>
                                  <td width="16.66%">
                                    {" "}
                                    <span className="font-14 text-secondary font-bold ">
                                      User Name
                                    </span>
                                  </td>
                                  <td width="16.66%">
                                    {" "}
                                    <span className="font-14 text-secondary font-bold">
                                      Email
                                    </span>
                                  </td>
                                  {integration.type === "SMS" || integration.type === "IMESSAGE"
                                    || integration.type === "MY_REPCHAT" || integration.type === "RING_CENTRAL"
                                    || integration.type === "ZOOM_SMS" ? (
                                    <td width="16.66%">
                                      <span className="font-14 text-secondary font-bold">
                                        Ref Id
                                      </span>
                                    </td>
                                  ) : (
                                    <td width="16.66%"></td>
                                  )}
                                  {integration.type === "SMS" || integration.type === "IMESSAGE"
                                    || integration.type === "MY_REPCHAT" || integration.type === "WEBSITE"
                                    || integration.type === "RING_CENTRAL" || integration.type === "EMAIL"
                                    || integration.type === "ZOOM_SMS" ?
                                    <td width="16.66%"></td> : ""
                                  }
                                  {
                                    integration.type === "IMESSAGE" ?
                                      <td width="16.66%" className="text-center">
                                        {" "}
                                        <span className="font-14 text-secondary font-bold ">
                                          Account Type
                                        </span>
                                      </td>
                                      : ""
                                  }


                                  <td width="16.66%" className="text-center">
                                    {" "}
                                    <span className="font-14 text-secondary font-bold ">
                                      {/* {integration.type === "IMESSAGE" ? "Status" : " Account Type"} */}
                                      Status
                                    </span>
                                  </td>
                                  <td className="text-center text-nowrap">
                                    <span className="font-14 text-secondary font-bold ">
                                      Last Archived On
                                    </span>
                                  </td>

                                </tr>
                                {integration.list &&
                                  integration.list.length > 0 &&
                                  integration.list.map((channel: any) => {
                                    return (
                                      <tr>
                                        {integration.type === "SMS" || integration.type === "IMESSAGE" ? (
                                          <td width="16.66%">
                                            {" "}
                                            <span className="text-brand font-14">
                                              {channel.platformType}
                                            </span>
                                          </td>
                                        ) : (
                                          <td width="16.66%" className="text-nowrap">
                                            <span className="text-brand font-14 action-btns unset-center">
                                              <TooltipCustom
                                                st={
                                                  <span>
                                                    {HelperService.truncate(
                                                      HelperService.removeUnderScore(
                                                        channel.name
                                                      ),
                                                      25
                                                    )}
                                                  </span>
                                                }
                                                message={channel.name}
                                                position="top"
                                                id="c_btn"
                                              ></TooltipCustom>
                                            </span>
                                          </td>
                                        )}
                                        <td className="text-nowrap">
                                          <span className="action-btns unset-center " >
                                            {channel.tenantUser?.firstName +
                                              " " +
                                              channel.tenantUser?.lastName}

                                          </span>
                                        </td>
                                        <td>{channel.tenantUser?.email}</td>
                                        {integration.type === "WEBSITE" ? (
                                          <td width="16.66%"></td>
                                        ) : integration.type === "EMAIL" ? (
                                          <td width="16.66%"></td>
                                        ) : (
                                          <td>
                                            <TooltipCustom
                                              st={
                                                <span>
                                                  {HelperService.truncate(
                                                    channel.refId,
                                                    15
                                                  )}
                                                </span>
                                              }
                                              message={channel.refId}
                                              position="top"
                                              id="c_btn"
                                            ></TooltipCustom>
                                          </td>
                                        )}


                                        {
                                          integration.type === "IMESSAGE"
                                            ? <td
                                              className="action-btns text-center unset-center"
                                            >

                                              <Button
                                                className="action-btn btn-view btn-light font-12"
                                                title="View"
                                                onClick={() => { setValue3("uia_id", channel.id); getCredentials(channel); setIMsgPasswordVisible(false) }}
                                              >
                                                <MdOutlineRemoveRedEye className="icon" />
                                                View
                                              </Button>
                                            </td> : <span> </span>

                                        }

                                        {
                                          integration.type === "IMESSAGE" ?
                                            <td
                                              className="text-center"
                                              width="16.66%"
                                            >
                                              {
                                                integration.type === "IMESSAGE" ?
                                                  <span className="status-badge active">
                                                    {channel.accountType}
                                                  </span>
                                                  :
                                                  ""
                                              }
                                            </td>
                                            : ""
                                        }

                                        <td
                                          className="text-center"
                                          width="16.66%"
                                        >
                                          {

                                            channel.accountStatus === "ACTIVE" ?
                                              <span className="font-14 text-success text-nowrap">Connected</span> :
                                              channel.accountStatus === "INACTIVE" ?
                                                <span className="font-14  text-reconnect text-nowrap">Disconnected </span> :
                                                channel.accountStatus === "CONFIGURATION_PENDING" ?
                                                  <span className="font-14 text-primary text-nowrap">Configuration Pending </span> :
                                                  channel.accountStatus === "CONNECTION_ERROR" ?
                                                    <span className="font-14 text-danger text-nowrap">Connection Error</span> :
                                                    channel.accountStatus === "RECONNECT" ?
                                                      <span className="font-14 text-primary text-nowrap">Reconnect</span> :
                                                      channel.accountStatus === "DELETED" ?
                                                        <span className="font-14 text-danger text-nowrap">Deleted</span> :
                                                        channel.accountStatus === "OLD_ACCOUNTS" ?
                                                          <span className="font-14 text-secondary text-nowrap">Old Account</span> :
                                                          ""

                                          }
                                        </td>
                                        <td className="text-center text-nowrap">{channel?.lastCrawlDate ? HelperService.getFormatedDateForDetail(channel?.lastCrawlDate) : "-"}</td>
                                      </tr>
                                    );
                                  })}
                              </table>
                            </div>
                          </Card>
                        </>
                      );
                    })}
                </div >
                {integrationSocialDetailList &&
                  integrationSocialDetailList.length > 0 ? (
                  <Card className="p-1 border-0">
                    <div className="text-end me-5 mb-2 mt-2 social-buy-btn-margin1">
                      <span className="me-2">
                        {totalConnectorsUsed}/{totalConnectors}
                      </span>
                    </div>
                    {integrationSocialDetailList.map((integration: any) => {
                      return (
                        <>
                          <Card className="inetegration-card border">
                            <div className="col-md-2 d-flex justify-content-center align-items-center">
                              <div className="d-flex gap-2 align-items-center">
                                <img
                                  src={logo(integration.type)}
                                  onError={(error: any) => {
                                    error.target.src = NoImage;
                                  }}
                                  width={30}
                                  alt=""
                                />
                                <span className=" font-medium">
                                  {integration.type !== "LINKEDIN"
                                    ? HelperService.titleCase(integration.type)
                                    : "LinkedIn"}
                                </span>
                              </div>
                            </div>
                            <div className="border-start col-md-10 table-responsive" style={{ scrollbarWidth: "thin" }}>
                              <table className="integration-table">
                                <tr>
                                  <td width="16.66%">
                                    <span className="font-14 text-secondary">
                                      Connected Accounts
                                    </span>
                                  </td>
                                  <td width="16.66%"></td>
                                  <td width="16.66%"></td>
                                  <td
                                    width="16.66%"
                                    className="text-center me-2"
                                  ></td>
                                </tr>
                                {integration.list &&
                                  integration.list.length > 0 &&
                                  integration.list.map((channel: any) => {
                                    return (
                                      <tr>
                                        <td width="16.66%">
                                          <span className="text-brand font-14">
                                            <TooltipCustom
                                              st={
                                                <span>
                                                  {HelperService.truncate(
                                                    HelperService.removeUnderScore(
                                                      channel.name
                                                    ),
                                                    50
                                                  )}
                                                </span>
                                              }
                                              message={channel.name}
                                              position="top"
                                              id="c_btn"
                                            ></TooltipCustom>
                                          </span>
                                        </td>
                                        <td>
                                          {channel.tenantUser?.firstName +
                                            " " +
                                            channel.tenantUser?.lastName}
                                        </td>
                                        <td>{channel.tenantUser?.email}</td>
                                        <td>
                                          <TooltipCustom
                                            st={
                                              <span>
                                                {HelperService.truncate(
                                                  channel.refId,
                                                  15
                                                )}
                                              </span>
                                            }
                                            message={channel.refId}
                                            position="top"
                                            id="c_btn"
                                          ></TooltipCustom>
                                        </td>

                                        <td
                                          className="text-center"
                                          width="16.66%"
                                        >
                                          <span className="status-badge active">
                                            {channel.accountType}
                                          </span>
                                        </td>
                                        <td
                                          className="text-center"
                                          width="16.66%"
                                        >
                                          {

                                            channel.accountStatus === "ACTIVE" ?
                                              <span className="font-14 text-success text-nowrap">Connected</span> :
                                              channel.accountStatus === "INACTIVE" ?
                                                <span className="font-14  text-reconnect text-nowrap">Disconnected </span> :
                                                channel.accountStatus === "CONFIGURATION_PENDING" ?
                                                  <span className="font-14 text-primary text-nowrap">Configuration Pending </span> :
                                                  channel.accountStatus === "CONNECTION_ERROR" ?
                                                    <span className="font-14 text-danger text-nowrap">Connection Error</span> :
                                                    channel.accountStatus === "RECONNECT" ?
                                                      <span className="font-14 text-primary text-nowrap">Reconnect</span> :
                                                      channel.accountStatus === "DELETED" ?
                                                        <span className="font-14 text-danger text-nowrap">Deleted</span> :
                                                        channel.accountStatus === "OLD_ACCOUNTS" ?
                                                          <span className="font-14 text-secondary text-nowrap">Old Account</span> :
                                                          ""

                                          }
                                        </td>
                                        <td className="text-center text-nowrap">{channel?.lastCrawlDate ? HelperService.getFormatedDateForDetail(channel?.lastCrawlDate) : "-"}</td>
                                      </tr>
                                    );
                                  })}
                              </table>
                            </div>
                          </Card>
                        </>
                      );
                    })}
                  </Card>
                ) : (
                  ""
                )
                }
              </>
            ) : (
              <div className="table-wrap position-relative">
                <div className="no-data-flound bg-white py-5 rounded-4">
                  <div className="text-center">
                    <img alt="" src={NoDataFound} width={110} className="mb-3" />
                    <h3 className="text-secondary font-12">
                      No data found
                    </h3>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <TableLoader />
        )
        }
      </div >
      {/* <DeleteModal
        action={action}
        show={showDelete}
        onDelete={(deleted: boolean) => deleteList(deleted)}
        btn="YES/NO"
        deleteMsg="Are you sure you want to disconnect?"
        deleteMethod="DELETE"
      /> */}
      < Offcanvas show={showConnect} onHide={handleCloseConnect} placement="end" >
        <ConnectBlade
          parentCallback={handleCallback}
          clientId={props.client?.id}
          availableSMS={totalSms}
          useSMSCount={totalSmsUsed}
          avaialbleWebsite={totalWebsite}
          usedWebsite={totalWebsiteUsed}
          availableEmail={totalEmail}
          usedEmail={totalEmailUsed}
          currentSubscriptionList={props?.currentSubscriptionList}
          avaialbleImessage={totalImessage}
          usedImessage={totalImessageUsed}
        />
      </Offcanvas >

      <Offcanvas show={showImport} onHide={() => setShowImport(false)} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Import</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form className="form-style" onSubmit={handleSubmit(handleUploadClick)} >
            <div className="upload-btn-container mb-2">
              <label htmlFor="fileImport"><PiUploadSimple size={18} className="me-3" />{files ? files?.name : "Upload"}</label>
              {/* <input type="file" id="tb-file-upload" accept=" " /> */}
              <input
                {...register("fileImport", { required: true })}
                type="file"
                id="fileImport"
                accept=" "
              // onChange={handleFileChange}
              />
            </div>
            <Row>
              <Col className="font-12 text-secondary">
                *You can upload zip files

                {errorFlag ? (
                  <Label title={fileErrorMsg} modeError={true} />
                ) : <>{errors.fileImport && (
                  <Label title="Please select file" modeError={true} />
                )}</>}
              </Col>
              {/* <Col className="item-align-end font-12" style={{ "textAlign": "end" }}>
                <a
                  href={require('./emails.csv')}
                  download="sample"
                  target="_blank"
                  rel="noreferrer">Download sample
                </a>
              </Col> */}
            </Row>
            <div className="pt-3 ">
              <div className="input-group mb-2 mt-1 w-100">
                <Password
                  requiredMsg={{
                    ...register1("newPassword",
                      {
                        required: "Please enter new password",
                        pattern: {
                          value:
                            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!-*])(?=.*[a-zA-Z@#$%^&+=!-*]).{6,}$/,
                          message:
                            "Passwords should contain a combination of four character types[Uppercase letters +Lowercase letters + Numbers+Special Character]",
                        },
                      }
                    ),
                  }}
                  inputFieldClassName="form-control border-end-0 border-start-0 ps-0"
                  id=""
                  name=""
                  label="Password"
                  placeholder="Password"
                  method={(event: any) => {
                    handleOnChange1(event.target.value, "newPassword");
                  }}
                  onError={
                    errors1.newPassword ? (
                      <div className="mb-2">
                        <Label
                          title={
                            errors1.newPassword.message
                              ? errors1.newPassword.message.toString()
                              : ""
                          }
                          modeError={true}
                          showStar={true}
                          type="password"
                        />
                      </div>
                    ) : (
                      ""
                    )
                  }
                />{" "}
              </div>
            </div>
            <div>
              <Button type="submit" id="import_btn" className="btn btn-brand-1 w-100">Import</Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>


      <Offcanvas show={showCredentials} onHide={() => setShowCredentials(false)} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Apple ID Credentials</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {
            !cresLoader ?
              <>
                <div className="form-style">
                  <div className="password-container">
                    <p>Apple Id : <b className="px-2">{appleId}</b></p>
                    <TooltipCustom
                      st={
                        <span>
                          <PiCopySimpleBold style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={() => handleCopy(appleId)} />
                        </span>
                      }
                      message="Copy"
                      position="top"
                      id="c_btn"
                    ></TooltipCustom>
                  </div>
                  <div className="password-container">
                    {/* ref={passwordRef} */}
                    <span>Password: <b className="px-2"> {iMsgpasswordVisible ? pass : <span style={{ marginTop: "3px" }}>{'*'.repeat(pass.length)}</span>}</b></span>
                    {
                      pass !== "" && pass !== null && pass !== undefined ?
                        <div className=" d-flex gap-2">
                          <span className="icon " onClick={handleIMsgPasswordVisibility}>
                            {iMsgpasswordVisible ? <BsEyeSlash size={16} /> : <BsEye size={16} />}
                          </span>
                          <TooltipCustom
                            st={
                              <span>
                                <PiCopySimpleBold style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={() => handleCopy(pass)} />
                              </span>
                            }
                            message="Copy"
                            position="top"
                            id="c_btn"
                          ></TooltipCustom>
                        </div>
                        : ""
                    }
                  </div>
                  <div className="mb-3">
                    {
                      pass !== "" && pass !== null && pass !== undefined ?
                        <div className='d-flex justify-content-end mt-2'>
                          <a
                            id="reset-btn"
                            className='font-12 cursor-pointer'
                            onClick={() => {
                              setShowClearPasswordConfirmationModel(true);
                            }}
                          >Clear Password</a>
                        </div>
                        : ""
                    }
                  </div>
                </div>


                <Form className="form-style" onSubmit={handleSubmit2(handleCredentialsSave, handleCredentialsError)} autoComplete="off">
                  <Col lg={12} className="mb-3">
                    <AsyncSelect
                      {...register("host")}
                      cacheOptions
                      defaultOptions
                      onChange={onHostSelection}
                      loadOptions={hostPromiseOption}
                      defaultValue={selectedHost?.id !== undefined ? selectedHost : ""}
                      placeholder="Select host"
                    />
                    {(watchVariable2.host === undefined ||
                      watchVariable2.host === "") &&
                      hostFlag ? (
                      <Label title="Please select host" modeError={true} />
                    ) : (
                      ""
                    )}
                  </Col>
                  <div>
                    <Button type="submit" id="credentials_save_btn" className="btn btn-brand-1 w-100">Save</Button>
                  </div>
                </Form>
              </>
              : <TableLoader />
          }

        </Offcanvas.Body>
      </Offcanvas>


      <Offcanvas show={usernameEditPopup} onHide={() => { setUsernameFlag(false); setUsernameEditPopup(false) }} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Edit Username</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form className="form-style" onSubmit={handleSubmit3(handleSaveUsername)} autoComplete="off">
            <Col lg={12} className="mb-3">
              <AsyncSelect
                {...register3("username")}
                cacheOptions
                defaultOptions
                onChange={onUserSelection}
                loadOptions={usernamePromiseOption}
                value={selectedUsername}
                placeholder="Select user"
              />
              {(selectedUsername.value === undefined ||
                selectedUsername.value === "") &&
                userNameFlag ? (
                <Label title="Please select user" modeError={true} />
              ) : (
                ""
              )}
            </Col>
            {
              watchVariable3.integrationType === "IMESSAGE" || watchVariable3.integrationType === "SMS" || watchVariable3.integrationType === "WHATSAPP" ?
                <Col lg={12} className="mb-3">
                  <Form.Select
                    {...register3("accountType", { required: watchVariable3.integrationType === "IMESSAGE" || watchVariable3.integrationType === "SMS" ? true : false })}
                    onChange={(e: any) => {
                      setValue3("accountType", e.target.value);
                    }}
                  >
                    <option selected disabled={true} value="">Account type</option>
                    <option value="BUSINESS">Business</option>
                    <option value="PERSONAL">Personal</option>
                  </Form.Select>
                  {errors3.accountType && (
                    <Label
                      title={"Please select account type"}
                      modeError={true}
                      showStar={true}
                      type=""
                    />
                  )
                  }
                </Col>
                : ''
            }

            <div>
              <Button type="submit" id="username_save_btn" className="btn btn-brand-1 w-100">Save</Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={showWebsiteEditModal}
        onHide={() => setShowWebsiteEditModal(false)}
        placement="end"
        className="righSide-modal size-md"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Edit Website</Offcanvas.Title>
        </Offcanvas.Header>
        <OffcanvasBody>
          <Form
            className="w-100 form-style"
            name="Verify"
            id="Verify"
            onSubmit={handleSubmit4(handleEditWebsite)}
          >
            <FloatingLabel
              controlId="FirstName"
              label="Website URL"
              className="mb-3"
            >
              <Form.Control
                {...register4("name", {
                  required: "Please enter website URL",
                  pattern: {
                    value:
                      /^(https?|ftp):\/\/[^\s/$.?#]+(\.[^\s/$.?#]+)+[^\s]*(\/[^\s]*)?[a-zA-Z]{2,}\/?$/i,
                    message:
                      "Please enter valid website URL starts with https://www.",
                  },
                })}
                type="text"
                className="form-control ps-0 "
                placeholder="Website URL"
                defaultValue="https://www."
                onChange={(e) => {
                  watchVariable4.name = e.target.value;
                  setValue4("name", e.target.value);
                }}
              />
              {errors4.name && (
                <Label
                  title={errors4.name.message?.toString()}
                  modeError={true}
                  showStar={true}
                  type=""
                />
              )}
            </FloatingLabel>
            <div>
              <Button type="submit" className="btn btn-brand-1 w-100" id="website-edit-save">
                Save
              </Button>
            </div>
          </Form>
        </OffcanvasBody>
      </Offcanvas>

      <Modal show={showClearPasswordConfirmationModel} onHide={() => setShowClearPasswordConfirmationModel(false)} centered>
        <Modal.Header
          closeButton
          onClick={() => setShowClearPasswordConfirmationModel(false)}
          className=" border-0 pb-0"
        ></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <h2 className="text-danger">
              <PiSealWarning size={70} />
            </h2>
            <h4 className="font-18">
              Are you sure you want to clear password
            </h4>
          </div>
        </Modal.Body>
        <Modal.Footer className=" justify-content-center border-0 pt-0">
          <Button
            className="btn-brand-light"
            onClick={() => setShowClearPasswordConfirmationModel(false)}
          >
            No
          </Button>
          <Button className="btn-brand-1"
            onClick={() => resetImessageCredentials()}
            id="save11" >
            Yes
          </Button>
        </Modal.Footer>
      </Modal >

    </>
  );
};
export default TabIntegration;








