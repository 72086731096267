import { useEffect, useState } from "react";
import { Col, Card, Button, Offcanvas, Form, Table, FloatingLabel } from "react-bootstrap";
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp, MdOutlineModeEdit, MdOutlineRemoveRedEye } from "react-icons/md";
import { toast } from "react-toastify";
import NoDataFound from "../../../../assets/images/empty_item.svg";
import TableLoader from "../../../Common/TableLoader/TableLoader";
import AIPagination from "../../../Common/Pagination/AIPagination";
import WebService from "../../../../Services/WebService";
import moment from "moment";
import HelperService from "../../../../Services/HelperService";
import { AiOutlineSend } from "react-icons/ai";
import { useForm } from "react-hook-form";
import { Label } from "../../../Common/Label/Label";
import TabSubscription from "../TabSubscription/TabSubscription";

interface PropsData {
  clientId: any;
}

const InvoiceHistory = (props: PropsData) => {
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState("dateTime");
  const [orderBy, setOrderBy] = useState("DESC");
  const [clickable, setClickable] = useState("dateTime");
  const [paymentHistoryList, setPaymentHistoryList] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [usernameEditPopup, setUsernameEditPopup] = useState<any>(false);
  const [userNameFlag, setUsernameFlag] = useState<any>(false);
  const [payment, setPayment] = useState(null);
  const [showPaymentStatusUpdatePopup, setShowPaymentStatusUpdatePopup] = useState<any>(false);
  const pagination = 100;


  const {
    register: register,
    handleSubmit: handleSubmit,
    setValue: setValue,
    formState: { errors: errors },
    clearErrors: clearErrors,
    setError: setError,
    watch: watch,
    reset: reset,
  } = useForm();
  let watchVariable = watch();

  const {
    register: register3,
    handleSubmit: handleSubmit3,
    setValue: setValue3,
    formState: { errors: errors3 },
    clearErrors: clearErrors3,
    setError: setError3,
    watch: watch3,
    reset: reset3,
  } = useForm();
  let watchVariable3 = watch3();

  useEffect(() => {
    getPaymentHistory();
  }, [page, sortBy, orderBy]);


  const getPaymentHistory = () => {
    setLoader(true);
    const obj = {
      page: page.toString(),
      client_id: props.clientId,
      sort_by: sortBy,
      order_by: orderBy,
    };
    var qurey = new URLSearchParams(obj).toString();
    return WebService.getAPI({
      action: `client-invoices?` + qurey,
      body: null,
      isShowError: true,
    })
      .then((res: any) => {
        setPaymentHistoryList(res?.list);
        setTotalCount(res?.count)
        setLoader(false);
      })
      .catch((error: any) => {
        setLoader(false);
        return error;
      });
  };

  const handleSaveUsername = () => {
    WebService.addLoader("username_save_btn");
    var body = {
      "clientId": props.clientId,
      "transactionId": payment,
      // "userId": watchVariable3.user_id
      "email": watchVariable3.email
    }
    return WebService.postAPI({
      action: `payment/request`,
      body: body,
      isShowError: true,
    })
      .then((res: any) => {
        toast.success(res.message);
        setUsernameEditPopup(false);
        WebService.removeLoader("username_save_btn");
        setPayment(null);
      })
      .catch((error: any) => {
        toast.error(error.message);
        WebService.removeLoader("username_save_btn");
        return error;
      });
  }

  const handleView = (path: any) => {
    window.open(path)
  }

  const handleUpdatePaymentStatus = () => {
    WebService.addLoader("save-btn");
    const obj = {
      "clientId": props.clientId,
      "transactionId": watchVariable.trxId,
      "paymentStatus": watchVariable.updatePaymentStatus
    };

    return WebService.putAPI({
      action: `inovice-status`,
      body: obj,
      isShowError: true,
    })
      .then((res: any) => {
        getPaymentHistory();
        setValue("trxId", "");
        setValue("updatePaymentStatus", "");
        WebService.removeLoader("save-btn");
        setShowPaymentStatusUpdatePopup(false)
      })
      .catch((error: any) => {
        WebService.removeLoader("save-btn");
        return error;
      });
  }




  return (
    <>
      {!loader ?
        paymentHistoryList?.length > 0 ?
          <>
            <label>Current Plan</label>
            <TabSubscription
              clientId={props.clientId}
              changeInSubscription={(flag: any) => { }}
            />
            <label>Invoice History</label>
            <div className="table-card" >
              <Card className="table-container-sub table-responsive" style={{ scrollbarWidth: "thin" }} >
                <Table className="table-style">
                  <thead>
                    <tr>
                      <th
                        className="cursor-pointer"
                        onClick={() => {
                          setSortBy("invoice_number");
                          setClickable("invoice_number");
                          setOrderBy(orderBy === "DESC" ? "ASC" : "DESC");
                        }}
                      >
                        Invoice No
                        {sortBy === "invoice_number" && clickable === "invoice_number" ? (
                          orderBy === "DESC" ? (
                            <MdOutlineKeyboardArrowDown
                              className="sort-icon cursor-pointer"
                              size={14}
                            />
                          ) : (
                            <MdOutlineKeyboardArrowUp
                              className="sort-icon cursor-pointer"
                              size={14}
                            />
                          )
                        ) : (
                          ""
                        )}
                      </th>
                      <th>
                        Status
                      </th>

                      <th
                        className="cursor-pointer"
                        onClick={() => {
                          setSortBy("created_date");
                          setClickable("created_date");
                          setOrderBy(orderBy === "DESC" ? "ASC" : "DESC");
                        }}
                      >
                        Date
                        {sortBy === "created_date" && clickable === "created_date" ? (
                          orderBy === "DESC" ? (
                            <MdOutlineKeyboardArrowDown
                              className="sort-icon cursor-pointer"
                              size={14}
                            />
                          ) : (
                            <MdOutlineKeyboardArrowUp
                              className="sort-icon cursor-pointer"
                              size={14}
                            />
                          )
                        ) : (
                          ""
                        )}
                      </th>
                      <th
                        className="cursor-pointer"
                        onClick={() => {
                          setSortBy("amount");
                          setClickable("amount");
                          setOrderBy(orderBy === "DESC" ? "ASC" : "DESC");
                        }}
                      >
                        Amount
                        {sortBy === "amount" && clickable === "amount" ? (
                          orderBy === "DESC" ? (
                            <MdOutlineKeyboardArrowDown
                              className="sort-icon cursor-pointer"
                              size={14}
                            />
                          ) : (
                            <MdOutlineKeyboardArrowUp
                              className="sort-icon cursor-pointer"
                              size={14}
                            />
                          )
                        ) : (
                          ""
                        )}
                      </th>
                      <th className="">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentHistoryList && paymentHistoryList.length > 0 ? (
                      paymentHistoryList.map((payment: any, index: any) => {
                        return (
                          <tr className={payment.id}>
                            <td>{payment?.invoiceNumber} </td>
                            <td >
                              <span className={payment?.transactionStatus == "PAID" ? "status-badge active" : payment?.transactionStatus == "PAYMENT_DUE" ? "status-badge payment_due" : "status-badge trial"}>
                                {HelperService.titleCase(payment?.transactionStatus).replaceAll("_", " ")}
                              </span>
                            </td>
                            <td>{moment(payment.createdDate).format("MM-DD-YY  hh:mm A")}</td>
                            <td>$ {payment.amount}</td>
                            <td>
                              <div className="action-btns" style={{ display: 'flex', gap: '8px', justifyContent: 'flex-start', alignItems: 'center' }}>
                                {payment?.transactionStatus === "PAID" ||
                                  payment?.transactionStatus === "PAYMENT_DUE" ||
                                  payment?.transactionStatus === "PENDING"
                                  ? (
                                    <Button
                                      className="action-btn btn-view btn-light font-12"
                                      title="View"
                                      onClick={() => {
                                        handleView(payment?.invoicePath);
                                      }}
                                    >
                                      <MdOutlineRemoveRedEye className="align-text-top" size={16} />
                                      View
                                    </Button>
                                  ) : ""
                                }

                                {payment?.transactionStatus === "PAYMENT_DUE"
                                  ? (
                                    <Button
                                      className="action-btn btn-trial btn-light font-12"
                                      title="Send Mail"
                                      onClick={() => {
                                        setPayment(payment.id);
                                        setUsernameEditPopup(true);
                                        clearErrors3("email")
                                        reset3()
                                      }}
                                    >
                                      <AiOutlineSend className="align-text-top" size={12} />
                                      Send Mail
                                    </Button>

                                  ) : ""
                                }

                                {payment?.transactionStatus === "PAYMENT_DUE" ? (
                                  <Button
                                    className="action-btn btn-delete btn-light font-12"
                                    style={{ borderRadius: "15px" }}
                                    title="Send Mail"
                                    onClick={() => {
                                      setShowPaymentStatusUpdatePopup(true);
                                      setValue("updatePaymentStatus", "PAYMENT_DUE");
                                      setValue("trxId", payment.id)
                                    }}
                                  >
                                    <MdOutlineModeEdit className="align-text-top" size={12} />
                                    Edit
                                  </Button>

                                ) : ""
                                }

                              </div>
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                          </tr>
                        );
                      })
                    ) : ""}
                  </tbody>
                </Table>
                <AIPagination
                  pagination={pagination}
                  totalItems={totalCount}
                  changePage={(page: number) => {
                    setPage(page);
                  }}
                  changeCurrentPage={page}
                />
              </Card>
            </div>
          </>
          :
          <Card>
            <div className="table-wrap position-relative w-100">
              <div className="no-data-found w-100">
                <div className="text-center">
                  <img
                    src={NoDataFound}
                    width={110}
                    className="mb-3"
                    alt={""}
                  />
                  <h3 className="text-secondary font-12">
                    No Record Found
                  </h3>
                </div>
              </div>
            </div>
          </Card>
        :
        <TableLoader />
      }




      <Offcanvas show={usernameEditPopup} onHide={() => { setUsernameFlag(false); setUsernameEditPopup(false) }} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Send Email</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form className="form-style" onSubmit={handleSubmit3(handleSaveUsername)} autoComplete="off">
            <Col lg={12} className="mb-3">
              {/* <AsyncSelect
                {...register3("username")}
                cacheOptions
                defaultOptions
                onChange={onUserSelection}
                loadOptions={usernamePromiseOption}
                value={selectedUsername}
                placeholder="Select user"
              />
              {(selectedUsername.value === undefined ||
                selectedUsername.value === "") &&
                userNameFlag ? (
                <Label title="Please select user" modeError={true} />
              ) : (
                ""
              )} */}
              <FloatingLabel controlId="Email" label="Email" className="mb-3">
                <input
                  {...register3("email", {
                    required: "Please enter email",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,60}$/i,
                      message: "Invalid email format",
                    },
                  })}
                  type="text"
                  className="form-control ps-0"
                  placeholder="Email"
                  onChange={(e) => {
                    setValue3("email", e.target.value);
                  }}
                />
                {errors3.email && (
                  <Label
                    title={errors3.email.message?.toString()}
                    modeError={true}
                    showStar={true}
                    type=""
                  />
                )}
              </FloatingLabel>
            </Col>
            <div>
              <Button type="submit" id="username_save_btn" className="btn btn-brand-1 w-100">Send Email</Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>


      <Offcanvas show={showPaymentStatusUpdatePopup} onHide={() => { setShowPaymentStatusUpdatePopup(false) }} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Edit Payment Status</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form className="form-style" onSubmit={handleSubmit(handleUpdatePaymentStatus)} autoComplete="off">
            <Col lg={12} className="mb-3">
              <Form.Select
                {...register("updatePaymentStatus", { required: true })}
                onChange={(e: any) => {
                  setValue("updatePaymentStatus", e.target.value)
                }}
                aria-label="Select Platform mt-2 mb-2"
              >
                <option value="" disabled={true}>Select Payment Status</option>
                <option
                  value="PAID"
                  selected={watchVariable.updatePaymentStatus === "PAID"}
                >
                  Paid
                </option>
                <option
                  value="PAYMENT_DUE"
                  selected={watchVariable.updatePaymentStatus === "PAYMENT_DUE"}
                >Payment Due
                </option>
              </Form.Select>
            </Col>
            <div>
              <Button type="submit" id="save-btn" className="btn btn-brand-1 w-100">Save</Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>

    </>
  )
}
export default InvoiceHistory;








