import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  Offcanvas,
  Form,
  InputGroup,
  Table,
} from "react-bootstrap";
import WebService from "../../../../Services/WebService";
import { toast } from "react-toastify";
import NoDataFound from "../../../../assets/images/empty_item.svg";
import { IoSearchOutline } from "react-icons/io5";
import TableLoader from "../../../Common/TableLoader/TableLoader";
import AIPagination from "../../../Common/Pagination/AIPagination";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import AddSubscription from "./AddFTP";
import "./ftp.scss";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useSelector } from "react-redux";
import { PiTrash } from "react-icons/pi";
import DeleteModal from "../../../Common/DeleteModal/DeleteModal";
interface PropsData {
  clientId: any;
}
const TabFTP = (props: PropsData) => {
  const [showAddBlade, setShowAddBlade] = useState(false);
  const handleCloseAddBlade = () => {
    setShowAddBlade(false);
    setId("");
  };
  const handleShowAddBlade = () => {
    setShowAddBlade(true);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [loader, setLoader] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [FTPList, setFTPList] = useState<any[]>([]);
  const [sortBy, setSortBy] = useState("createdDate");
  const [orderBy, setOrderBy] = useState("DESC");
  const [page, setPage] = useState(1);
  const [id, setId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [clickable, setClickable] = useState("user_name");
  const [passwordVisibleList, setPasswordVisibleList] = useState<any[]>([]);
  const [showDelete, setShowDelete] = useState(false);
  const [action, setActiony] = useState("");

  useEffect(() => {
    if (props.clientId) {
      getFTPList(props.clientId);
    }
  }, [sortBy, orderBy, page, keyword, props.clientId]);

  const getFTPList = (clientId: any) => {
    setLoader(true);
    const obj = {
      page: page.toString(),
      sort_by: sortBy,
      order_by: orderBy,
      client_id: clientId,
      keyword: keyword,
    };
    let qurey = new URLSearchParams(obj).toString();
    return WebService.getAPI({
      action: `ftp/users/list?` + qurey,
      body: null,
      isShowError: true,
    })
      .then((res: any) => {
        setFTPList(res.list);
        setTotalCount(res.count);
        setLoader(false);
      })
      .catch((error: any) => {
        setFTPList([]);
        toast.error(error.message);
        setLoader(false);
        return error;
      });
  };
  const convertToStars = (word: any) => {
    return word.replace(/./g, "*"); // Replace each character with a star
  };
  const handlePasswordVisibility = (index: any) => {
    setPasswordVisibleList((prevList) => {
      if (prevList.includes(index)) {
        return prevList.filter((item) => item !== index);
      } else {
        return [...prevList, index];
      }
    });
  };

  const deleteList = (isDelete: any) => {
    if (isDelete) {
      getFTPList(props.clientId);
    }
    setShowDelete(false);
  };
  return (
    <>
      <Row className=" justify-content-end">
        <Col md={6} className="d-flex gap-3 justify-content-end mb-3">
          <InputGroup className="table-search-box border">
            <InputGroup.Text id="basic-addon1">
              <IoSearchOutline className="icon" />
            </InputGroup.Text>
            <Form.Control
              placeholder="Search for anything"
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === "Enter") {
                  setKeyword(e.currentTarget.value);
                }
              }}
            />
          </InputGroup>

          <Button
            className="btn-brand-1 text-nowrap"
            onClick={handleShowAddBlade}
          >
            + Add
          </Button>
        </Col>
      </Row>
      <div className="table-card">
        <Card className="table-container">
          <Table className="table-style">
            <thead>
              <tr>
                <th
                  className="cursor-pointer"
                  onClick={() => {
                    setSortBy("user_name");
                    setClickable("user_name");
                  }}
                >
                  User Name
                  {sortBy === "user_name" &&
                    orderBy === "DESC" &&
                    clickable === "user_name" ? (
                    <MdOutlineKeyboardArrowDown
                      className="sort-icon cursor-pointer"
                      size={14}
                      onClick={() => {
                        setOrderBy("ASC");
                        setSortBy("user_name");
                        setClickable("user_name");
                      }}
                    />
                  ) : clickable === "user_name" ? (
                    <MdOutlineKeyboardArrowUp
                      className="sort-icon cursor-pointer"
                      size={14}
                      onClick={() => {
                        setOrderBy("DESC");
                        setSortBy("user_name");
                      }}
                    />
                  ) : (
                    ""
                  )}
                </th>

                <th
                  className="cursor-pointer"
                  onClick={() => {
                    setSortBy("password");
                    setClickable("password");
                  }}
                >
                  Password
                  {sortBy === "password" &&
                    orderBy === "DESC" &&
                    clickable === "password" ? (
                    <MdOutlineKeyboardArrowDown
                      className="sort-icon cursor-pointer"
                      size={14}
                      onClick={() => {
                        setOrderBy("ASC");
                        setSortBy("password");
                        setClickable("password");
                      }}
                    />
                  ) : clickable === "password" ? (
                    <MdOutlineKeyboardArrowUp
                      className="sort-icon cursor-pointer"
                      size={14}
                      onClick={() => {
                        setOrderBy("DESC");
                        setSortBy("password");
                      }}
                    />
                  ) : (
                    ""
                  )}
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => {
                    setSortBy("host_name");
                    setClickable("host_name");
                  }}
                >
                  Host Name
                  {sortBy === "host_name" &&
                    orderBy === "DESC" &&
                    clickable === "host_name" ? (
                    <MdOutlineKeyboardArrowDown
                      className="sort-icon cursor-pointer"
                      size={14}
                      onClick={() => {
                        setOrderBy("ASC");
                        setSortBy("host_name");
                        setClickable("host_name");
                      }}
                    />
                  ) : clickable === "host_name" ? (
                    <MdOutlineKeyboardArrowUp
                      className="sort-icon cursor-pointer"
                      size={14}
                      onClick={() => {
                        setOrderBy("DESC");
                        setSortBy("host_name");
                      }}
                    />
                  ) : (
                    ""
                  )}
                </th>
                <th
                  className="cursor-pointer"
                  onClick={() => {
                    setSortBy("port");
                    setClickable("port");
                  }}
                >
                  Port
                  {sortBy === "port" &&
                    orderBy === "DESC" &&
                    clickable === "port" ? (
                    <MdOutlineKeyboardArrowDown
                      className="sort-icon cursor-pointer"
                      size={14}
                      onClick={() => {
                        setOrderBy("ASC");
                        setSortBy("port");
                        setClickable("port");
                      }}
                    />
                  ) : clickable === "port" ? (
                    <MdOutlineKeyboardArrowUp
                      className="sort-icon cursor-pointer"
                      size={14}
                      onClick={() => {
                        setOrderBy("DESC");
                        setSortBy("port");
                      }}
                    />
                  ) : (
                    ""
                  )}
                </th>               
              </tr>
            </thead>
            <tbody>
              {!loader ? (
                <>
                  {FTPList && FTPList.length > 0 ? (
                    FTPList.map((plan: any) => {
                      return (
                        <tr>
                          <td>{plan.user_name}</td>
                          <td width="200px">
                            <span className="me-2 p-1 px-1">
                              {passwordVisibleList.includes(plan.id)
                                ? plan.password
                                : convertToStars(plan.password)}
                            </span>

                            {passwordVisibleList.includes(plan.id) ? (
                              <BsEye
                                className="cursor-pointer"
                                size={16}
                                onClick={() =>
                                  handlePasswordVisibility(plan.id)
                                }
                              />
                            ) : (
                              <BsEyeSlash
                                className="cursor-pointer"
                                size={16}
                                onClick={() =>
                                  handlePasswordVisibility(plan.id)
                                }
                              />
                            )}
                          </td>
                          <td>{plan.host_name}</td>
                          <td>{plan.port}</td>                          
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={6}>
                        <div className="table-wrap position-relative w-100">
                          <div className="no-data-found1 w-100">
                            <div className="text-center">
                              <img
                                src={NoDataFound}
                                width={110}
                                className="mb-3"
                              />
                              <h3 className="text-secondary font-12">
                                No Record Found
                              </h3>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </>
              ) : (
                <tr>
                  <td colSpan={6}>
                    <TableLoader customClass={"remove-loader-height"} />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <AIPagination
            pagination={100}
            totalItems={totalCount}
            changePage={(page: number) => {
              setPage(page);
            }}
            changeCurrentPage={page}
          />
        </Card>
      </div>

      <Offcanvas
        show={showAddBlade}
        onHide={handleCloseAddBlade}
        placement="end"
      >
        <AddSubscription
          id={id}
          clientId={props.clientId ?? props.clientId}
          closeCanvasFlag={(flag: any) => {
            if (flag) {
              getFTPList(props.clientId);
              // props.changeInSubscription(true)
            }
            handleCloseAddBlade();
          }}
        />
      </Offcanvas>
    </>
  );
};
export default TabFTP;
