import { useEffect, useState } from "react";
import { Row, Col, Form, Button, Card } from "react-bootstrap";
import PageTitle from "../../../components/PageTitle";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Label } from "../../Common/Label/Label";
import AsyncSelect from "react-select/async";
import WebService from "../../../Services/WebService";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CreatableSelect from "react-select/creatable";
import "./manageclient.scss";
import { useSelector } from "react-redux";

const EditClientAdminUser = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const [_id, setId] = useState("");
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
    watch,
  } = useForm();
  let params = useParams();
  let watchVariable = watch();
  const [isStateDropdownDisabled, setStateDropdownDisabled] = useState(false);
  const [_isCityDropdownDisabled, setCityDropdownDisabled] = useState(false);
  const [isCountryDropdownDisabled, setCountryDropdownDisabled] =
    useState(false);
  const [_cityFlag, setCityFlag] = useState(false);
  const [stateFlag, setStateFlag] = useState(false);
  const [countryFlag, setCountryFlag] = useState(false);
  // const [selectedTags, setSelectedTags] = useState([]);
  // const [domainFlag, setDomainFlag] = useState(false);
  const [cityName, setCityName] = useState<any>("");
  // eslint-disable-line react-hooks/exhaustive-deps
  const [_paymentStatus, setPaymentStatus] = useState(""); // eslint-disable-line no-unused-vars
  const [selectedCountryOption, setSelectedCountryOption] = useState({
    value: "select_country",
    label: "Select Country",
  });
  const [selectedStateOption, setSelectedStateOption] = useState({
    value: "Select_state",
    label: "Select State",
  });
  useEffect(() => {
    if (params.id) {
      setId(params.id);
      getClientDetails(params.id);
    } else {
      setId("");
      watchVariable.on_demand_encryption = false;
      setValue("on_demand_encryption", false);

      watchVariable.importer = false;
      setValue("importer", false);

      watchVariable.unified_achiever = false;
      setValue("unified_achiever", false);

      watchVariable.new_review_system = false;
      setValue("new_review_system", false);

      watchVariable.voice_calls = false;
      setValue("voice_calls", false);
    }
  }, [params.id]);

  const getClientDetails = (id: any) => {
    return WebService.getAPI({
      action: `client/` + id,
      body: null,
      isShowError: true,
    })
      .then((res: any) => {
        if (res.city != null) {
          setCityName(res?.city?.cityName);
          watchVariable.cityName = res?.city?.cityName;
          setValue("cityName", res?.city?.cityName);
        }

        watchVariable.name = res.name;
        setValue("name", res.name);

        watchVariable.email = res.email;
        setValue("email", res.email);

        watchVariable.billing_address_1 = res.billing_address_1;
        setValue("billing_address_1", res.billing_address_1);
        watchVariable.billing_address_2 = res.billing_address_2;
        setValue("billing_address_2", res.billing_address_2);

        watchVariable.zipcode = res.city.zipCode;
        setValue("zipcode", res.city.zipCode);

        let obj1 = { value: res.country.id, label: res.country.countryName };
        watchVariable.countryId = res.country.id;
        setValue("countryId", res.country.id);
        setSelectedCountryOption(obj1);

        let obj2 = { value: res.state.id, label: res.state.stateName };
        watchVariable.stateId = res.state.id;
        setValue("stateId", res.state.id);
        setSelectedStateOption(obj2);

        let obj3 = { value: res.city.id, label: res.city.cityName };
        watchVariable.cityId = res.city.id;
        watchVariable.cityId = res.city.id;
        setValue("cityId", res.city.id);

        watchVariable.on_demand_encryption = res.on_demand_encryption;
        setValue("on_demand_encryption", res.on_demand_encryption);

        watchVariable.importer = res.importer;
        setValue("importer", res.importer);

        watchVariable.unified_achiever = res.unified_achiever;
        setValue("unified_achiever", res.unified_achiever);

        watchVariable.new_review_system = res.new_review_system;
        setValue("new_review_system", res.new_review_system);

        watchVariable.voice_calls = res.voice_calls;
        setValue("voice_calls", res.voice_calls);

        watchVariable.emailSystem = res.emailSystem;
        setValue("emailSystem", res.emailSystem)
        setPaymentStatus(res?.client_subscription?.paymentStatus);
        setValue("aiModel", res?.ai_model?.aiModelName);
        setValue("chatbotEnabled", res?.chatbotEnabled);
        setValue("emailNotification", res?.emailNotification);

        if (res?.parentClientName && res?.parentClientId) {
          setValue("parentClientId", res.parentClientId);
        }

      })
      .catch((error: any) => {
        return error;
      });
  };
  const handleSaveClient = () => {
    if (watchVariable.cityId === undefined || watchVariable.cityId === "") {
      setCityFlag(true);
      return;
    }
    if (watchVariable.stateId === undefined || watchVariable.stateId === "") {
      setStateFlag(true);
      return;
    }
    if (
      watchVariable.countryId === undefined ||
      watchVariable.countryId === ""
    ) {
      setCountryFlag(true);
      return;
    }

    // if (selectedTags.length === 0) {
    //   setDomainFlag(true);
    //   return;
    // }

    // setDomainFlag(false);
    setCityFlag(false);
    setStateFlag(false);
    setCountryFlag(false);
    // let domain: any = [];
    // selectedTags.forEach((tags: any) => {
    //   domain.push(tags.label);
    // });
    // watchVariable.domains = domain;
    WebService.addLoader("save1");

    if (params.id) {
      watchVariable.id = params.id;
      return WebService.putAPI({
        action: `client`,
        body: watchVariable,
        isShowError: true,
      })
        .then((res: any) => {
          toast.success(res.message);
          WebService.removeLoader("save1");
          navigate("/manage-clientadmin/view-client/" + params.id);
        })
        .catch((error: any) => {
          toast.error(error.message);
          WebService.removeLoader("save1");
          return error;
        });
    } else {
      return WebService.postAPI({
        action: `client`,
        body: watchVariable,
        isShowError: true,

      })
        .then((res: any) => {
          toast.success(res.message);
          WebService.removeLoader("save1");
          navigate("/manage-clientadmin/view-client/" + res.id);

        })
        .catch((error: any) => {
          toast.error(error.message);
          WebService.removeLoader("save1");
          return error;
        });
    }
  };
  const handleError = (errors: any) => {
    if (watchVariable.cityId === undefined || watchVariable.cityId === "") {
      setCityFlag(true);
    }
    if (watchVariable.stateId === undefined || watchVariable.stateId === "") {
      setStateFlag(true);
    }
    if (
      watchVariable.countryId === undefined ||
      watchVariable.countryId === ""
    ) {
      setCountryFlag(true);
    }

    // if (selectedTags.length === 0) {
    //   setDomainFlag(true);
    // } else {
    //   setDomainFlag(false);
    // }
  };
  const handleOnChange = (value: any, type: any) => {
    if (type === "name") {
      watchVariable.name = value;
      setValue("name", value);
      clearErrors("name");
    } else if (type === "email") {
      watchVariable.email = value;
      setValue("email", value);
      clearErrors("email");
    } else if (type === "address1") {
      watchVariable.billing_address_1 = value;
      setValue("billing_address_1", value);
      clearErrors("billing_address_1");
    } else if (type === "address2") {
      watchVariable.billing_address_2 = value;
      setValue("billing_address_2", value);
      clearErrors("billing_address_2");
    } else if (type === "billingEmail") {
      watchVariable.billingEmail = value;
      setValue("billingEmail", value);
      clearErrors("billingEmail");
    }



  };
  const countryPromiseOptions = (inputValue: any) =>
    new Promise<any[]>((resolve) => {
      return WebService.getAPI({
        action: `country/list`,
        body: null,

      })
        .then((res: any) => {
          if (res.length > 0) {
            let updatedOptions = res.map((country: any) => ({
              value: country.id,
              label: country.countryName,
            }));

            resolve(updatedOptions);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          resolve([]);
          return error;
        });
    });
  const cityPromiseOptions = (inputValue: any) =>
    new Promise<any[]>((resolve) => {
      if (watchVariable.stateId === "" || watchVariable.stateId === undefined) {
        return;
      }
      return WebService.getAPI({
        action: `cities/list?state_id=` + watchVariable.stateId,
        body: null,

      })
        .then((res: any) => {
          if (res.length > 0) {
            let updatedOptions = res.map((city: any) => ({
              value: city.city_id,
              label: city.cityName,
            }));
            resolve(updatedOptions);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          resolve([]);
          return error;
        });
    });

  const handleZipCodeChange = (zipCode: any) => {
    watchVariable.zipCode = zipCode;
    setValue("zipcode", zipCode);
    getDetailByZipCode();
  };

  const handleCityNameChange = (cityName: any) => {
    watchVariable.cityName = cityName;
    setValue("cityName", cityName);
  };

  const getDetailByZipCode = () => {
    return WebService.getAPI({
      action: `zipcode?zipcode=` + watchVariable.zipCode,
      body: null,
      isShowError: true,

    })
      .then((res: any) => {
        if (res.city != null) {
          watchVariable.cityId = res.city.id;
          setValue("cityId", res.city.id);
          setCityName(res?.city?.cityName);
          watchVariable.cityName = res?.city?.cityName;
          setValue("cityName", res?.city?.cityName);
        }
        if (res.country != null) {
          setSelectedCountryOption({
            value: res.country.id,
            label: res.country.countryName,
          });
          watchVariable.countryId = res.country.id;
          setValue("countryId", res.country.id);
          if (res.state != null) {
            setSelectedStateOption({
              value: res.state.id,
              label: res.state.stateName,
            });
            watchVariable.stateId = res.state.id;
            setValue("stateId", res.state.id);
          }
          setStateDropdownDisabled(true);
          setCityDropdownDisabled(true);
          setCountryDropdownDisabled(true);
        } else {
          setSelectedCountryOption({
            value: "",
            label: "",
          });
          setSelectedStateOption({
            value: "",
            label: "",
          });
          // setSelectedCityOption({
          //   value: "",
          //   label: "",
          // });
          setValue("cityName", "");
          setCityName("");
          setStateDropdownDisabled(false);
          setCityDropdownDisabled(false);
          setCountryDropdownDisabled(false);
        }
      })
      .catch((error: any) => {
        return error;
      });
  };

  const onCountrySelection = (selectedOption: any) => {
    setSelectedCountryOption(selectedOption);
    watchVariable.countryId = selectedOption.value;
    setValue("countryId", selectedOption.value);
    setCountryFlag(false);
  };

  const onStateSelection = (selectedOption: any) => {
    watchVariable.stateId = selectedOption.value;
    setValue("stateId", selectedOption.value);
    setSelectedStateOption(selectedOption);
    setStateFlag(false);
  };

  const statePromiseOptions = (inputValue: any) =>
    new Promise<any[]>((resolve) => {
      if (
        watchVariable.countryId === "" ||
        watchVariable.countryId === undefined
      ) {
        return;
      }
      return WebService.getAPI({
        action: `state/list?country_id=` + watchVariable.countryId,
        body: null,

      })
        .then((res: any) => {
          if (res.length > 0) {
            let updatedOptions = res.map((state: any) => ({
              value: state.state_id,
              label: state.stateName,
            }));
            resolve(updatedOptions);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          resolve([]);
          return error;
        });
    });

  const handleFeatureSelection = (value: any, type: any) => {
    if (type === "on_demand_encryption") {
      watchVariable.on_demand_encryption = value;
      setValue("on_demand_encryption", value);
    } else if (type === "importer") {
      watchVariable.importer = value;
      setValue("importer", value);
    } else if (type === "unified_achiever") {
      watchVariable.unified_achiever = value;
      setValue("unified_achiever", value);
    } else if (type === "new_review_system") {
      watchVariable.new_review_system = value;
      setValue("new_review_system", value);
    } else if (type === "voice_calls") {
      watchVariable.voice_calls = value;
      setValue("voice_calls", value);
    } else if (type === "chatbotEnabled") {
      watchVariable.chatbotEnabled = value;
      setValue("chatbotEnabled", value);
    } else if (type === "emailNotification") {
      watchVariable.emailNotification = value;
      setValue("emailNotification", value);
    }


  };


  return (
    <>
      <div className="page-contetn page-flagging">
        <div className="d-flex justify-content-between align-items-center mb-3">
          {params.id === undefined || params.id === "" ? (
            <PageTitle Title="Add Client" />
          ) : (
            <PageTitle Title="Edit Client" />
          )}
        </div>
        <Card className="content-card">
          <Form
            className="form-style normal"
            name="Verify"
            id="Verify"
            onSubmit={handleSubmit(handleSaveClient, handleError)}
          >
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{t("add_client.Name")}</Form.Label>
                  <Form.Control
                    type="text"
                    {...register("name", {
                      required: true,
                    })}
                    placeholder={t("add_client.Enter_name")}
                    onChange={(e: any) => {
                      handleOnChange(e.target.value, "name");
                    }}
                  />
                  {errors.name && (
                    <Label
                      title={t("add_client.nameValidation")}
                      modeError={true}
                      showStar={true}
                      type=""
                    />
                  )}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{t("add_client.Email")}</Form.Label>
                  <Form.Control
                    {...register("email", {
                      required: t("Login.EmailValidation1"),
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: t("Login.EmailValidation2"),
                      },
                    })}
                    type="text"
                    placeholder={t("add_client.Enter_email")}
                    onChange={(e: any) => {
                      handleOnChange(e.target.value, "email");
                    }}
                  />
                  {errors.email && (
                    <Label
                      title={errors.email.message?.toString()}
                      modeError={true}
                      showStar={true}
                      type=""
                    />
                  )}
                </Form.Group>
              </Col>

              <hr style={{ color: " #676767" }}></hr>
              <Col md={12} className="pt-3">
                <h4 className="font-18 font-medium">
                  {t("add_client.billing_details")}
                </h4>
              </Col>
              <Col lg={3} md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{t("add_client.address")} 1</Form.Label>
                  <Form.Control
                    type="text"
                    {...register("billing_address_1", {
                      required: false,
                    })}
                    placeholder={t("add_client.Enter_address")}
                    onChange={(e: any) => {
                      handleOnChange(e.target.value, "address1");
                    }}
                  />
                  {errors.billing_address_1 && (
                    <Label
                      title={t("add_client.addressValidation")}
                      modeError={true}
                      showStar={true}
                      type=""
                    />
                  )}
                </Form.Group>
              </Col>
              <Col lg={3} md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{t("add_client.address")} 2</Form.Label>
                  <Form.Control
                    type="text"
                    {...register("billing_address_2", {})}
                    placeholder={t("add_client.Enter_address")}
                    onChange={(e: any) => {
                      handleOnChange(e.target.value, "address2");
                    }}
                  />
                </Form.Group>
              </Col>
              <Col lg={3} md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{t("add_client.zipcode")}</Form.Label>
                  <Form.Control
                    type="number"
                    {...register("zipcode", {
                      required: true,
                    })}
                    placeholder={t("add_client.Enter_zipcode")}
                    onChange={(e: any) => {
                      handleZipCodeChange(e.target.value);
                    }}
                  />
                  {errors.zipcode && (
                    <Label
                      title={t("add_client.zipcodeValidation")}
                      modeError={true}
                      showStar={true}
                      type=""
                    />
                  )}
                </Form.Group>
              </Col>
              <Col lg={3} md={6}>
                <Form.Group className="mb-3" controlId="formBasiccountry">
                  <Form.Label>{t("add_client.country")}</Form.Label>
                  <AsyncSelect
                    {...register("countryId")}
                    id="country_select"
                    value={selectedCountryOption}
                    cacheOptions
                    defaultOptions
                    onChange={onCountrySelection}
                    loadOptions={countryPromiseOptions}
                    placeholder={t("add_client.Select_Country")}
                    isDisabled={isCountryDropdownDisabled}
                  />

                  {(watchVariable.countryId === undefined ||
                    watchVariable.countryId === "") &&
                    countryFlag ? (
                    <Label
                      title={t("add_client.Select_country_validation")}
                      modeError={true}
                    />
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>
              <Col lg={3} md={6}>
                <Form.Group className="mb-3" controlId="formBasicstate">
                  <Form.Label>{t("add_client.state")}</Form.Label>
                  <div style={{ zIndex: "999px" }}>
                    <AsyncSelect
                      {...register("stateId")}
                      id="state_select"
                      value={selectedStateOption}
                      cacheOptions
                      key={"asdasd_" + watchVariable.countryId}
                      defaultOptions
                      onChange={onStateSelection}
                      loadOptions={statePromiseOptions}
                      placeholder={t("add_client.Select_State")}
                      isDisabled={isStateDropdownDisabled}
                    />
                  </div>
                  {(watchVariable.stateId === undefined ||
                    watchVariable.stateId === "") &&
                    stateFlag ? (
                    <Label
                      title={t("add_client.Select_state_validation")}
                      modeError={true}
                    />
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>
              <Col lg={3} md={6}>
                <Form.Group className="mb-3" controlId="formBasicCity">
                  <Form.Label>{t("add_client.city")}</Form.Label>
                  <Form.Control
                    type="text"
                    {...register("cityName", {
                      required: true,
                    })}
                    placeholder="Enter city"
                    onChange={(e: any) => {
                      handleCityNameChange(e.target.value);
                    }}
                    value={cityName}
                  />
                  {cityName === "" ? errors.cityName && (
                    <Label
                      title="Please enter city name"
                      modeError={true}
                      showStar={true}
                      type=""
                    />
                  ) : ""}
                </Form.Group>
              </Col>

              <Col md={4}></Col>
              <hr style={{ color: " #676767" }}></hr>

              <Col lg={3} md={6} className="mt-2">
                <Row>
                  <h4 className="font-16 font-medium text-nowrap">
                    Mail Service Provider
                  </h4>
                </Row>
                <Form.Select
                  {...register("emailSystem", { required: false })}
                  onChange={(e: any) => {
                    setValue("emailSystem", e.target.value);
                  }}
                >
                  <option selected disabled={true} value="">Select mail service provider</option>
                  <option value="MICROSOFT">Microsoft</option>
                  <option value="GSUITE">G-Suite</option>
                </Form.Select>
              </Col>


              <Col lg={3} md={6} className="mt-2">
                <Row>
                  <h4 className="font-16 font-medium">
                    AI Model
                  </h4>
                </Row>
                <Form.Select
                  {...register("aiModel", { required: true })}
                  onChange={(e: any) => {
                    setValue("aiModel", e.target.value);
                  }}
                >
                  <option selected value="KEYWORD">Keyword</option>
                  <option value="LLAMA3">LLAMA3</option>
                  {/* <option value="BERT">BERT</option> */}
                </Form.Select>
              </Col>


              {/* <Col lg={4} md={6} className="mt-2">
                <Row>
                  <h4 className="font-16 font-medium text-nowrap">
                    Reconnect mail notification frequency
                  </h4>
                </Row>
                <Row lg={4} md={6} className="px-2">
                  <Form.Select>
                    <option value="DAILY">Daily</option>
                    <option value="WEEKLY">Weekly</option>
                    <option selected value="NONE">None</option>
                  </Form.Select>
                </Row>
              </Col> */}

              <hr style={{ color: "#676767" }} className="mt-4"></hr>
              {
                params.id === undefined || params.id === "" ?
                  <>
                    <Col md={12} className="pt-3">
                      <h4 className="font-18 font-medium">
                        {t("add_client.features")}
                      </h4>
                    </Col>
                    <Col md={12} className="d-flex gap-3 mb-3">
                      <div className="d-flex gap-3 mb-4">
                        <Form.Check
                          {...register("on_demand_encryption")}
                          type="checkbox"
                          id="On-Demand"
                          label="Email Encryption"
                          checked={
                            watchVariable.on_demand_encryption === true ? true : false
                          }
                          onChange={() => {
                            handleFeatureSelection(
                              !watchVariable.on_demand_encryption,
                              "on_demand_encryption"
                            );
                          }}
                        />

                        <Form.Check
                          type="checkbox"
                          {...register("voice_calls")}
                          id="Voice Calls"
                          label="Vault"
                          onChange={() => {
                            handleFeatureSelection(
                              !watchVariable.voice_calls,
                              "voice_calls"
                            );
                          }}
                        />

                        <Form.Check
                          type="checkbox"
                          {...register("chatbotEnabled")}
                          id="chatbotEnabled"
                          label="AI Compliance Bot"
                          onChange={() => {
                            handleFeatureSelection(
                              !watchVariable.chatbotEnabled,
                              "chatbotEnabled"
                            );
                          }}
                        />

                        <Form.Check
                          {...register("emailNotification")}
                          type="checkbox"
                          id="emailNotification"
                          label="Onboarding Email Notifications"
                          defaultChecked={true}
                          onChange={() => {
                            handleFeatureSelection(
                              !watchVariable.emailNotification,
                              "emailNotification"
                            );
                          }}
                        />

                      </div>
                    </Col>
                  </>
                  : ""
              }
            </Row>
            <Row>
              <Col className="text-end">
                <Button
                  className="btn btn-brand-outline-light px-4"
                  onClick={() => {
                    {
                      params.id === undefined || params.id === "" ?
                        navigate("/manage-clientadmin")
                        : navigate(`/manage-clientadmin/view-client/${params.id}`);
                    }

                  }}
                >
                  {t("Common.cancel")}
                </Button>
              </Col>
              <Col>
                <Button className="btn btn-brand-1" type="submit" id="save1">
                  {t("Common.save")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </div >
    </>
  );
};
export default EditClientAdminUser;
