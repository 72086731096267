import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
interface PropData {
  page_type: any;
}
export const AccessControl = (props: PropData) => {
  const loginUserRoleType = useSelector((state: any) => state?.profile?.loginUserRoleType ?? "");
  var navigate = useNavigate();

  const [systemAdminAccessMenu, setSystemAdminAccessMenu] = useState([
    "/integration",
    "/dashboard",
    "/manage-clients",
    "/manage-clients/view-client/",
    "/manage-clients/add-client",
    "/manage-clients/add-client",
    "/privacy-policy",
    "/subscription",
    "/subscription/add-subscription",
    "/subscription/add-subscription",
    "/compliance-user",
    "/export",
    "/profile",
    "/connectors-report",
    "/imessage",
    "/whatsapp",
    "/connection-dashboard",
    "/keywords",
    "/user-machine"
  ]);

  const [clientAdminAccessMenu, setClientAdminAccessMenu] = useState([
    "/clientadmin-overview",
    "/client-admin-client",
    "/clientadmin-connector-report",
    "/clientadmin-contacts",
    "/manage-clientadmin",
    "/edit-client"
  ]);

  const [adminComplianceUserAccessMenu, setAdminComplianceUserAccessMenu] = useState([
    "/keywords"
  ]);

  useEffect(() => {
    if (loginUserRoleType === "SYSTEM_ADMIN") {
      if (!systemAdminAccessMenu.includes(props.page_type)) {
        navigate("/access-denied");
        return;
      }
    } else if (loginUserRoleType === "ACCOUNT_ADMIN") {
      if (!clientAdminAccessMenu.includes(props.page_type)) {
        navigate("/access-denied");
        return;
      }
    } else if (loginUserRoleType === "ADMIN_COMPLIANCE_USER") {
      if (!adminComplianceUserAccessMenu.includes(props.page_type)) {
        navigate("/access-denied");
        return;
      }
    }
  }, []);

  return <></>;
};
