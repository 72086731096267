import { useState } from "react";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { PiUploadSimple } from "react-icons/pi";
import { toast } from "react-toastify";
import WebService from "../../../../Services/WebService";
import { Label } from "../../../Common/Label/Label";
import { TooltipCustom } from "../../../Common/Tooltip/Tooltip";
import { IoMdInformationCircleOutline } from "react-icons/io";
interface PropData {
    parentCallback: any;
    clientID: any;
}
const ImportUser = (props: PropData) => {
    const [files, setFile] = useState<File>();
    const [fileErrorMsg, setFileErrorMsg] = useState("");
    const [errorFlag, setErrorFlag] = useState(false);
    const [createEmailConnector, setCreateEmailConnector] = useState<boolean>(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    const handleUploadClick = () => {
        if (!files) {
            setErrorFlag(true);
            setFileErrorMsg("Please select CSV file")
            return;
        }
        if (files) {
            if (files.type !== "text/csv") {
                setErrorFlag(true)
                setFileErrorMsg("Please select valid CSV file only")
                return;
            }
        }
        setErrorFlag(false)
        setFileErrorMsg("")
        WebService.addLoader("import_btn");
        return WebService.uploadAPI({
            action: `import-user?client_id=${props.clientID}&create_email=${createEmailConnector}`,
            body: {},
            _file: files,
            key: "file",
        })
            .then((res: any) => {
                toast.success(res.message);
                props.parentCallback(false)
                WebService.removeLoader("import_btn");
            })
            .catch((error) => {
                if (error?.response?.data?.message) {
                    toast.error(error?.response?.data?.message);
                }
                WebService.removeLoader("import_btn");
                props.parentCallback(false)
                return [];
            });
    };



    return (
        <>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Import Users</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Form className="form-style" onSubmit={handleSubmit(handleUploadClick)} >
                    <div className="upload-btn-container mb-2">
                        <label htmlFor="fileImport"><PiUploadSimple size={18} className="me-3" />{files ? files?.name : "Upload File"}</label>
                        <input
                            {...register("fileImport", { required: true })}
                            type="file"
                            id="fileImport"
                            accept=" "
                            onChange={handleFileChange}
                        />
                    </div>
                    <Row>
                        <Col className="font-12 text-secondary mb-3">
                            *You can upload CSV files of Users

                            {errorFlag ? (
                                <Label title={fileErrorMsg} modeError={true} />
                            ) : <>{errors.fileImport && (
                                <Label title="Please select file" modeError={true} />
                            )}</>}
                        </Col>
                        <Col className="item-align-end font-12" style={{ "textAlign": "end" }}>
                            <a
                                href={require('./sample.csv')}
                                download="sample"
                                target="_blank"
                                rel="noreferrer">Download sample
                            </a>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            {/* <Form.Check
                                type="checkbox"
                                id="smsType"
                                label="Create email connector"
                                onChange={() => {
                                    setCreateEmailConnector((prevType: any) => !prevType);
                                }}
                            /> */}
                            <Form.Check
                                type="checkbox"
                                id="createEmailConnector"
                                label={
                                    <>
                                        {"Configure Auto Email Connector "}
                                        <TooltipCustom
                                            st={
                                                <span>
                                                    <IoMdInformationCircleOutline />
                                                </span>
                                            }
                                            message={
                                                "If you select this checkbox, an email connector will automatically be created for all users you import."
                                            }
                                            position="top"
                                            id="info-icon-tooltip"
                                        />
                                    </>
                                }
                                onChange={() => {
                                    setCreateEmailConnector((prevType: any) => !prevType);
                                }}
                            />
                        </Col>
                    </Row>
                    <div>
                        <Button type="submit" id="import_btn" className="btn btn-brand-1 w-100">Import</Button>
                    </div>
                </Form>
            </Offcanvas.Body>

        </>
    )
}
export default ImportUser;



