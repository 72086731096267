import { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import FACEBOOK from "../../../../assets/images/fb-logo.svg";
import INSTAGRAM from "../../../../assets/images/socialChannels/insta-logo.svg";
import LINKEDIN from "../../../../assets/images/linkedin-logo.svg";
import ZOOM from "../../../../assets/images/zoom-logo.svg";
import X from "../../../../assets/images/socialChannels/twitter_icon.svg";
import WEBSITE from "../../../../assets/images/socialChannels/web-icon.svg";
import EMAIL from "../../../../assets/images/email-archive-icon.svg";
import ConnectCard from "./ConnectCard";
import { useTranslation } from "react-i18next";
import TEAMS from "../../../../assets/images/microsoft-teams.svg";
import SMS from "../../../../assets/images/socialChannels/android1.svg";
import HelperService from "../../../../Services/HelperService";
import NoDataFound from "../../../../assets/images/empty_item.svg";
import RING_CENTRAL from "../../../../assets/images/socialChannels/Ring-central-icon.png";
import IMESSAGE from "../../../../assets/images/socialChannels/IMessage_logo.png"
import MY_REPCHAT from "../../../../assets/images/socialChannels/MyRepChat-icon.jpeg"
import { useSelector } from "react-redux";

interface PropData {
  parentCallback: any;
  availableSMS: any;
  useSMSCount: any;
  avaialbleWebsite: any;
  usedWebsite: any;
  currentSubscriptionList?: any;
  availableEmail?: any;
  clientId: any;
  usedEmail: any;
  data?: any;
  avaialbleImessage?: any;
  usedImessage?: any;
}

const ConnectBlade = (props: PropData) => {
  const [validChannels, setValidChannels] = useState<any[]>([]);

  useEffect(() => {
    const filteredChannels = props?.currentSubscriptionList.filter((channel: any) =>
      channel.integrationStatus === "ON" &&
      (channel?.codeName === "SMS" || channel?.codeName === "WEBSITE"
        || channel?.codeName === "EMAIL" || channel?.codeName === "IMESSAGE" || channel?.codeName === "MY_REPCHAT")
    );
    setValidChannels(filteredChannels);
  }, [props.currentSubscriptionList]);

  const { t } = useTranslation();
  const handleCallback = () => {
    props.parentCallback(false);
  };
  const project = (projectName: any) => {
    switch (projectName) {
      case "FACEBOOK": return FACEBOOK;
      case "INSTAGRAM": return INSTAGRAM;
      case "LINKEDIN": return LINKEDIN;
      case "ZOOM": return ZOOM;
      case "X": return X;
      case "EMAIL": return EMAIL;
      case "TEAMS": return TEAMS;
      case "SMS": return SMS;
      case "WEBSITE": return WEBSITE;
      case "RING_CENTRAL": return RING_CENTRAL;
      case "IMESSAGE": return IMESSAGE;
      case "MY_REPCHAT": return MY_REPCHAT;
      default: return NoDataFound
    }
  }





  return (
    <>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Connectors</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>

        {
          validChannels.length > 0 ?
            validChannels.map((channel: any, index: any) => {
              return (
                channel.integrationStatus === "ON" && (channel?.codeName === "SMS"
                  || channel?.codeName === "WEBSITE" || channel?.codeName === "EMAIL" || channel?.codeName === "IMESSAGE"
                  || channel?.codeName === "MY_REPCHAT"
                ) ?
                  // <ConnectCard
                  //   socialLogo={project(channel?.codeName)}
                  //   // socialName={channel?.codeName === "SMS" ? "Android SMS" :
                  //   //   channel?.codeName === "IMESSAGE" ? "iMessage" :
                  //   //     channel?.codeName === "MY_REPCHAT" ? "My RepChat"
                  //   //       : HelperService.removeUnderScore(HelperService.titleCase(channel?.codeName))}
                  //   parentCallback={handleCallback}
                  //   data={props?.data}
                  //   clientId={props.clientId}
                  // /> : 
                  ""
                  : ""
              )
            })
            : <div className="table-wrap position-relative w-100">
              <div className="no-data-found w-100">
                <div className="text-center">
                  <img
                    src={NoDataFound}
                    width={110}
                    className="mb-3"
                    alt={""}
                  />
                  <h3 className="text-secondary font-12">
                    No Valid Connectors Found
                  </h3>
                </div>
              </div>
            </div>

        }
      </Offcanvas.Body>
    </>
  );
};
export default ConnectBlade;








