import { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

interface propsData {
  client: any;
  clientInfo?: any;
  sendData?: any;
}
const TabGeneral = (props: propsData) => {
  const [editLink, setEditLink] = useState("");
  const [client, setClient] = useState<any>(null);
  const [integrationList, setIntegrationList] = useState<any[]>([]);
  const [domains, setDomains] = useState([]);
  const { t } = useTranslation();
  const { register, setValue, watch } = useForm();
  const watchVariable = watch();

  useEffect(() => {
    watchVariable.on_demand_encryption = false;
    setValue("on_demand_encryption", false);
    watchVariable.importer = false;
    watchVariable.unified_achiever = false;
    setValue("unified_achiever", false);
    watchVariable.new_review_system = false;
    setValue("new_review_system", false);
    watchVariable.voice_calls = false;
    setValue("voice_calls", false);
    if (props.client && props.client !== null) {
      setEditLink("/manage-clientadmin/add-client/" + props.client.id);
      setClient(props.client);

      if (props.client.domains?.length > 0) {
        setDomains(props.client.domains);
      }

      watchVariable.on_demand_encryption = props.client.on_demand_encryption;
      setValue("on_demand_encryption", props.client.on_demand_encryption);
      watchVariable.importer = props.client.importer;
      setValue("importer", props.client.importer);
      watchVariable.unified_achiever = props.client.unified_achiever;
      setValue("unified_achiever", props.client.unified_achiever);
      watchVariable.new_review_system = props.client.new_review_system;
      setValue("new_review_system", props.client.new_review_system);
      watchVariable.voice_calls = props.client.voice_calls;
      setValue("voice_calls", props.client.voice_calls);
    }
  }, [props.client]);


  useEffect(() => {
    if (
      props.clientInfo &&
      (props.clientInfo !== null || props.clientInfo !== undefined)
    ) {
      setIntegrationList(props.clientInfo.user_integrations);
    }
  }, [props.clientInfo]);


  return (
    <>
      {props.client ? (
        <>
          <Row>
            <Col>
              <div className="d-flex justify-content-between align-items-center mb-0">
                <h4 className="font-bold font-20 mb-3">
                  {client ? client.name : ""}
                </h4>
                <Link to={editLink} className="btn btn-outline-secondary px-3">
                  <FiEdit size={16} /> {t("Common.Edit")}
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col> <div className="d-flex justify-content-between align-items-center mb-4">
              <h4 className="font-bold font-16 mb-3">
                {client ? client.email : ""}
              </h4>
            </div>
            </Col>
          </Row>
          <Row>
            <Col className="mb-2" lg={3}>
              <label className="mb-1">Inbound Archiving</label>
              <div className=" text-secondary font-14 mb-4">
                {client ? client.inbound_archiving : ""}
              </div>
            </Col>
            <Col lg={3}>
              <label className="mb-1">Outbound Archiving</label>
              <div className=" text-secondary font-14 mb-4">
                {client ? client.outbound_archiving : ""}
              </div>
            </Col>
          </Row>
          {/* <Row>
            {
              client?.emailSystem ?
                <Col className="mb-2" lg={3}>
                  <label className="mb-1">Email Service Provider</label>
                  <div className="text-secondary font-14 mb-4">
                    {client ? client.emailSystem === "GSUITE" ? "G-Suite" :
                      client.emailSystem === "MICROSOFT" ? "Microsoft" : client.emailSystem : ""}
                  </div>
                </Col>
                : ""
            }
            <Col className="mb-2" lg={3}>
              <label className="mb-1">Database Utilization</label>
              <div className="text-secondary font-14 mb-4">
                {client?.dbSize ? client?.dbSize : 0} GB
              </div>
            </Col>
          </Row> */}
          <Row>
            <Col className="mb-4" lg={3}>
              <label className="mb-1">S3 Utilization </label>
              <div className="text-secondary font-14 mb-4">
                {client?.s3Size ? client?.s3Size : 0} GB
              </div>
            </Col>
            {/* <Col className="mb-4" lg={3}>
              <label className="mb-1">AI Model</label>
              <div className="text-secondary font-14 mb-4">
                {client?.ai_model?.aiModelName}
              </div>
            </Col> */}
          </Row>
        </>
      ) : (
        ""
      )}
    </>
  );
};
export default TabGeneral;
