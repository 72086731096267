import { Button, Form, Offcanvas } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import WebService from "../../../../Services/WebService";
import { useEffect } from "react";
import { Label } from "../../../Common/Label/Label";
import { toast } from "react-toastify";
import "./ftp.scss";
import Password from "../../../Common/Password/Password";
import { VscAccount } from "react-icons/vsc";
import { useSelector } from "react-redux";
interface PropsData {
  id: any;
  closeCanvasFlag: any;
  clientId: any;
}

const AddSubscription = (props: PropsData) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
    setError,
    watch,
  } = useForm();
  let watchVariable = watch();
  const { t } = useTranslation();

  useEffect(() => {
    if (props.id !== "") {
      getUserById(props.id);
    }
  }, [props.id]);
  const getUserById = (id: any) => {
    return WebService.getAPI({
      action: `client/user?user_id=` + id,
      body: null,
      isShowError: true,
    })
      .then((res: any) => { })
      .catch((error: any) => {
        toast.error(error.message);
        return error;
      });
  };
  const handleSaveFTPUser = () => {
    watchVariable.clientId = props.clientId;
    WebService.addLoader("save11");
    return WebService.postAPI({
      action: `create/ftp/user`,
      body: watchVariable,
      isShowError: true,
    })
      .then((res: any) => {
        toast.success(res.message);
        WebService.removeLoader("save11");
        props.closeCanvasFlag(true);
      })
      .catch((error: any) => {
        toast.error(error.message);
        WebService.removeLoader("save11");
        props.closeCanvasFlag(false);
        return error;
      });
  };
  const handleOnChange = (value: any, type: any) => {
    if (type === "userName") {
      setValue("userName", value);
      clearErrors("userName");
      if (watchVariable.userName === "") {
        setError("userName", {
          type: "custom",
          message: "",
        });
      }
    } else if (type === "password") {
      watchVariable.password = value;
      setValue("password", value);
      clearErrors("password");
      if (watchVariable.password === "") {
        setError("password", {
          type: "custom",
          message: "",
        });
      }
    }
  };

  return (
    <>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          {props.id === "" ? "Add SFTP User" : "Edit SFTP User"}
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form
          className="w-100 form-style"
          name="Verify"
          id="Verify"
          onSubmit={handleSubmit(handleSaveFTPUser)}
        >
          <div className="form-group">
            <div className="input-group mb-2">
              <span className="input-group-text bg-white border-end-0 text-secondary">
                <VscAccount size={16} />
              </span>
              <input
                {...register("userName", {
                  required: "Please enter user name",
                  pattern: {
                    value: /^[a-zA-Z]+[a-zA-Z0-9]*$/,
                    message: "Username must start with a letter and contain only alphanumeric characters (no spaces or special characters)",
                  }
                })}
                type="text"
                className="form-control border-start-0 ps-0"
                placeholder="User name"
                onChange={(e) => {
                  handleOnChange(e.target.value, "userName");
                }}
              />
            </div>

            {errors.userName && (
              <div className="mb-4"> <Label
                title={errors.userName.message?.toString()}
                modeError={true}
                showStar={true}
                type=""
              /></div>
            )}
          </div>
          <div className="form-group mb-2">
            <div className="input-group mb-3 mt-1">
              <Password
                requiredMsg={{
                  ...register("password", {
                    required: "Please enter password",
                    pattern: {
                      value:
                        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!])(?=.*[a-zA-Z@#$%^&+=!]).{6,}$/,
                      message:
                        "Passwords should contain a combination of four character types[Uppercase letters +Lowercase letters + Numbers+Special Character]",
                    },
                  }),
                }}
                inputFieldClassName="form-control border-end-0 border-start-0 ps-0"
                id=""
                name=""
                label="Password"
                placeholder={t("Login.Password")}
                method={(event: any) => {
                  handleOnChange(event.target.value, "password");
                }}
                onError={
                  errors.password ? (
                    <div className="mb-4"><Label
                      title={
                        errors.password.message
                          ? errors.password.message.toString()
                          : ""
                      }
                      modeError={true}
                      showStar={true}
                      type="password"
                    /></div>
                  ) : (
                    ""
                  )
                }
              />
            </div>
          </div>

          <div>
            <Button type="submit" className="btn btn-brand-1 w-100" id="save11">
              Save
            </Button>
          </div>
        </Form>
      </Offcanvas.Body>
    </>
  );
};
export default AddSubscription;
