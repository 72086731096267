import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  Offcanvas,
  Form,
  InputGroup,
  Modal,
  Table,
} from "react-bootstrap";
import { PiDownloadSimple, PiSealWarning, PiTrash } from "react-icons/pi";
import ImportUser from "./ImportUser";
import NoDataFound from "../../../../assets/images/empty_item.svg";
import { useTranslation } from "react-i18next";
import WebService from "../../../../Services/WebService";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import HelperService from "../../../../Services/HelperService";
import DeleteModal from "../../../Common/DeleteModal/DeleteModal";
import AddUser from "./AddUser";
import { IoSearchOutline } from "react-icons/io5";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
  MdOutlineModeEdit,
} from "react-icons/md";
import TableLoader from "../../../Common/TableLoader/TableLoader";
import AIPagination from "../../../Common/Pagination/AIPagination";
import moment from "moment";
import { CgUnblock } from "react-icons/cg";
import { TbCloudDownload } from "react-icons/tb";
import { useSelector } from "react-redux";
import { TooltipCustom } from "../../../Common/Tooltip/Tooltip";

interface PropsData {
  client: any;
}
const TabUsers = (props: PropsData) => {
  const { t } = useTranslation();

  //development
  const directLoginUATURL = "https://uat-client.archiveintel.com/auth/redirect/";
  const directLogindevURL = "https://dev-client.archiveintel.com/auth/redirect/";
  const directLoginliveURL = "https://app.archiveintel.com/auth/redirect/";

  const { setValue, watch, reset } = useForm();
  let watchVariable = watch();
  // Add blade
  const [showAddBlade, setShowAddBlade] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [clientId, setClientId] = useState("");
  const [showPopUp, setShowPopup] = useState(false);
  const handleCloseAddBlade = () => {
    setShowAddBlade(false);
    setRoleFlag(false);
    setId("");
  };
  const handleShowAddBlade = () => {
    setRole("");
    setShowAddBlade(true);
    reset();

  };
  const [showImport, setShowImport] = useState(false);
  const handleCloseImport = () => setShowImport(false);
  const [loader, setLoader] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [userList, setUserList] = useState<any[]>([]);
  const [sortBy, setSortBy] = useState("");
  const [orderBy, setOrderBy] = useState("DESC");
  const [page, setPage] = useState(1);
  const [_roleFlag, setRoleFlag] = useState(false);
  const [action, setAction] = useState("");
  const [id, setId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [clickable, setClickable] = useState("first_name");
  const [role, setRole] = useState<any>("");
  const handleClosePopUp = () => {
    setShowPopup(false);
  };
  const [_selectedRole, setSelectedRole] = useState<any>({
    value: "Select role",
    label: "Select Role",
  });


  const handleDirectLogin = (email: any) => {
    let time_zone: any = new Date().getTimezoneOffset();
    let obj = { email: email };
    return WebService.postAPI({
      action: `login/direct?time_zone=${time_zone}`,
      body: obj,
      isShowError: true,

    })
      .then((res: any) => {
        if (res.jwtToken) {
          let url = "";
          if (window.location.href.includes("dev-admin")) {
            url = directLogindevURL;
          } else if (window.location.href.includes("uat-admin")) {
            url = directLoginUATURL;
          } else {
            url = directLoginliveURL;
          }
          window.open(url + res.jwtToken + "?fc=all", "_blank");
        }
      })
      .catch((error: any) => {
        toast.error(error.message);
        return error;
      });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (props.client) {
      getUserList(props.client.id);
      setClientId(props.client.id);
    }
  }, [sortBy, orderBy, page, props.client, keyword]);

  const getUserById = (id: any) => {
    return WebService.getAPI({
      action: `client/user?user_id=` + id + `&client_id=` + props.client.id,
      body: null,
      isShowError: true,

    })
      .then((res: any) => {
        setShowAddBlade(true);
        setId(res.id);
        setRole(res.role_type);
        watchVariable.first_name = res.first_name;
        setValue("first_name", res.first_name);
        watchVariable.first_name = res.first_name;
        setValue("last_name", res.last_name);
        watchVariable.first_name = res.email;
        setValue("email", res.email);
        let obj = {
          label: HelperService.removeUnderScore(res.role.role),
          value: res.role.id,
        };
        watchVariable.role_id = res.role.id;
        setValue("role_id", res.role.id);
        setSelectedRole(obj);
        setLoader(false);
      })
      .catch((error: any) => {
        toast.error(error.message);
        setLoader(false);
        return error;
      });
  };

  const getUserList = (clientId: any) => {
    setLoader(true);
    const obj = {
      page: page.toString(),
      sort_by: sortBy,
      order_by: orderBy,
      client_id: clientId,
      keyword: keyword,
    };
    let qurey = new URLSearchParams(obj).toString();
    return WebService.getAPI({
      action: `client/users/list?` + qurey,
      body: null,
      isShowError: true,

    })
      .then((res: any) => {
        setUserList(res.list);
        setTotalCount(res.count);
        setLoader(false);
      })
      .catch((error: any) => {
        toast.error(error.message);
        setLoader(false);
        return error;
      });
  };

  const deleteList = (isDelete: any) => {
    if (isDelete) {
      getUserList(props.client.id);
    }
    setShowDelete(false);
  };

  const handleCallback = (data: any) => {
    setShowImport(data);
    getUserList(props.client.id);
  };

  const handleSubscriptionStatus = (id: any, client_id: any) => {
    const obj =
    {
      "client_id": client_id,
      "id": id,
      "status": "ACTIVE"
    };
    return WebService.putAPI({
      action: `client/user-status?`,
      body: obj,
      isShowError: true,

    })
      .then((res: any) => {
        toast.success(res.message);
        getUserList(client_id);
      })
      .catch((error: any) => {
        toast.error(error.message);
        return error;
      });

  };

  const unblockUser = (id: any) => {
    WebService.addLoader("btn-unblock");
    return WebService.postAPI({
      action: `/unblock-user?client_id=${props.client.id}&user_id=${id}`,
      body: null,
      isShowError: true,

    })
      .then((res: any) => {
        toast.success(res.message);
        getUserList(props.client.id);
        WebService.removeLoader("btn-unblock");
      })
      .catch((error: any) => {
        WebService.removeLoader("btn-unblock");
        toast.error(error.message);
        return error;
      });

  };

  return (
    <>
      <Row className=" justify-content-end">
        <Col md={6} className="d-flex gap-3 justify-content-end mb-3">
          <InputGroup className="table-search-box border">
            <InputGroup.Text id="basic-addon1">
              <IoSearchOutline className="icon" />
            </InputGroup.Text>
            <Form.Control
              placeholder="Search for anything"
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === "Enter") {
                  setKeyword(e.currentTarget.value);
                }
              }}
            />
          </InputGroup>
          <Button
            className="btn-brand-light text-nowrap px-3"
            onClick={() => {
              setShowImport(true);
              reset();
            }}
          >
            <TbCloudDownload size={18} className="me-1" /> Import
          </Button>
          <Button
            className="btn-brand-1 text-nowrap"
            onClick={handleShowAddBlade}
          >
            + {t("Common.add")}
          </Button>
        </Col>
      </Row>

      <div className="table-card">
        <Card className="table-container table-responsive" style={{ scrollbarWidth: "thin" }}>
          <Table className="table-style">
            <thead>
              <tr>
                <th
                  className="cursor-pointer"
                  onClick={() => {
                    setSortBy("first_name");
                    setClickable("first_name");
                    setOrderBy(orderBy === "DESC" ? "ASC" : "DESC");
                  }}
                >
                  First Name
                  {sortBy === "first_name" && clickable === "first_name" ? (
                    orderBy === "DESC" ? (
                      <MdOutlineKeyboardArrowDown
                        className="sort-icon cursor-pointer"
                        size={14}
                      />
                    ) : (
                      <MdOutlineKeyboardArrowUp
                        className="sort-icon cursor-pointer"
                        size={14}
                      />
                    )
                  ) : (
                    ""
                  )}
                </th>

                <th
                  className="cursor-pointer"
                  onClick={() => {
                    setSortBy("last_name");
                    setClickable("last_name");
                    setOrderBy(orderBy === "DESC" ? "ASC" : "DESC");
                  }}
                >
                  Last Name
                  {sortBy === "last_name" && clickable === "last_name" ? (
                    orderBy === "DESC" ? (
                      <MdOutlineKeyboardArrowDown
                        className="sort-icon cursor-pointer"
                        size={14}
                      />
                    ) : (
                      <MdOutlineKeyboardArrowUp
                        className="sort-icon cursor-pointer"
                        size={14}
                      />
                    )
                  ) : (
                    ""
                  )}
                </th>

                <th
                  className="cursor-pointer"
                  onClick={() => {
                    setSortBy("email");
                    setClickable("email");
                    setOrderBy(orderBy === "DESC" ? "ASC" : "DESC");
                  }}
                >
                  Email
                  {sortBy === "email" && clickable === "email" ? (
                    orderBy === "DESC" ? (
                      <MdOutlineKeyboardArrowDown
                        className="sort-icon cursor-pointer"
                        size={14}
                      />
                    ) : (
                      <MdOutlineKeyboardArrowUp
                        className="sort-icon cursor-pointer"
                        size={14}
                      />
                    )
                  ) : (
                    ""
                  )}
                </th>

                <th
                  className="cursor-pointer"
                  onClick={() => {
                    setSortBy("date_onboard");
                    setClickable("date_onboard");
                    setOrderBy(orderBy === "DESC" ? "ASC" : "DESC");
                  }}
                >
                  Onboard Date
                  {sortBy === "date_onboard" && clickable === "date_onboard" ? (
                    orderBy === "DESC" ? (
                      <MdOutlineKeyboardArrowDown
                        className="sort-icon cursor-pointer"
                        size={14}
                      />
                    ) : (
                      <MdOutlineKeyboardArrowUp
                        className="sort-icon cursor-pointer"
                        size={14}
                      />
                    )
                  ) : (
                    ""
                  )}
                </th>

                <th
                  className="cursor-pointer"
                  onClick={() => {
                    setSortBy("role_id");
                    setClickable("role_id");
                    setOrderBy(orderBy === "DESC" ? "ASC" : "DESC");
                  }}
                >
                  Role
                  {sortBy === "role_id" && clickable === "role_id" ? (
                    orderBy === "DESC" ? (
                      <MdOutlineKeyboardArrowDown
                        className="sort-icon cursor-pointer"
                        size={14}
                      />
                    ) : (
                      <MdOutlineKeyboardArrowUp
                        className="sort-icon cursor-pointer"
                        size={14}
                      />
                    )
                  ) : (
                    ""
                  )}
                </th>
                <th>Login</th>
                <th
                  className="cursor-pointer"
                  onClick={() => {
                    setSortBy("last_login");
                    setClickable("last_login");
                    setOrderBy(orderBy === "DESC" ? "ASC" : "DESC");
                  }}
                >
                  Last Login
                  {sortBy === "last_login" && clickable === "last_login" ? (
                    orderBy === "DESC" ? (
                      <MdOutlineKeyboardArrowDown
                        className="sort-icon cursor-pointer"
                        size={14}
                      />
                    ) : (
                      <MdOutlineKeyboardArrowUp
                        className="sort-icon cursor-pointer"
                        size={14}
                      />
                    )
                  ) : (
                    ""
                  )}
                </th>
                <th className="">{t("Common.action")}</th>
              </tr>
            </thead>
            <tbody>
              {!loader ? (
                userList.length > 0 ? (
                  userList.map((user: any, index: any) => {
                    return (
                      <tr>
                        <td>
                          <TooltipCustom
                            st={<span>{HelperService.truncate(user.first_name, 15)}</span>}
                            message={user.first_name}
                            position="top"
                            id="c_btn"
                          ></TooltipCustom>
                        </td>
                        <td>
                          <TooltipCustom
                            st={<span>{HelperService.truncate(user.last_name, 15)}</span>}
                            message={user.last_name}
                            position="top"
                            id="c_btn"
                          ></TooltipCustom>
                        </td>
                        <td>
                          <TooltipCustom
                            st={<span>{HelperService.truncate(user.email, 30)}</span>}
                            message={user.email}
                            position="top"
                            id="c_btn"
                          ></TooltipCustom>
                        </td>
                        <td>{user.created_date
                          ? moment(user.created_date).format(
                            "MM-DD-YYYY  hh:mm A"
                          )
                          : "-"}
                        </td>
                        <td>
                          {user.role_type === "COMPLIANCE_OFFICER" ? "Compliance" : HelperService.removeUnderScore(user.role_type)}
                        </td>
                        <td>
                          <div className="">
                            <Button
                              className="btn-brand-1 "
                              style={{
                                fontSize: "12px",
                                height: "30px",
                                lineHeight: "14px",
                              }}
                              onClick={() => {
                                handleDirectLogin(user.email);
                              }}
                            >
                              {t("Common.login")}
                            </Button>
                          </div>
                        </td>
                        <td>
                          {user.last_login
                            ? moment(user.last_login).format(
                              "MM-DD-YYYY  hh:mm A"
                            )
                            : "-"}
                        </td>
                        <td>
                          <div className="action-btns" style={{ display: 'flex', gap: '8px', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <Button
                              className="action-btn btn-view btn-light font-12"
                              title="edit"
                              onClick={() => {
                                getUserById(user.id);
                              }}
                            >
                              <MdOutlineModeEdit
                                className="align-text-top"
                                size={16}
                              />
                              {t("Common.Edit")}
                            </Button>

                            <Button
                              className="action-btn btn-delete"
                              title="Delete"
                              onClick={() => {
                                setAction(
                                  "client-users?userId=" +
                                  user.id +
                                  `&clientId=` +
                                  props.client.id
                                );
                                setShowDelete(true);
                              }}
                            >
                              <PiTrash className="icon" />
                            </Button>

                            {/* {
                              user?.accountBlocked && (
                                <Button
                                  className="action-btn btn-view btn-light font-12"
                                  title="unblock"
                                  id="btn-unblock"
                                  onClick={() => {
                                    unblockUser(user.id);
                                  }}
                                >
                                  <CgUnblock
                                    className="align-text-top"
                                    size={16}
                                  />
                                  Unblock
                                </Button>
                              )
                            } */}
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={12}>
                      <div className="table-wrap position-relative w-100">
                        <div className="no-data-found w-100">
                          <div className="text-center">
                            <img
                              src={NoDataFound}
                              width={110}
                              className="mb-3"
                              alt={""}
                            />
                            <h3 className="text-secondary font-12">
                              {t("Common.No_Record_Found")}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan={12}>
                    <TableLoader customClass={"remove-loader-height"} />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card>

        <AIPagination
          pagination={100}
          totalItems={totalCount}
          changePage={(page: number) => {
            setPage(page);
          }}
          changeCurrentPage={page}
        />
      </div >
      <Offcanvas
        show={showAddBlade}
        onHide={handleCloseAddBlade}
        placement="end"
      >
        <AddUser
          id={id}
          role={role}
          clientId={clientId}
          closeCanvasFlag={(flag: any) => {
            if (flag) {
              handleCloseAddBlade();
              getUserList(props.client.id);
            }
          }}
        />
      </Offcanvas>

      {/* Import Keyword Blade */}
      <Offcanvas show={showImport} onHide={handleCloseImport} placement="end">
        <ImportUser
          parentCallback={handleCallback}
          clientID={props.client ? props.client.id : ""}
        />
      </Offcanvas>
      <Modal show={showPopUp} onHide={handleClosePopUp} centered>
        <Modal.Header
          closeButton
          // onClick={() => handleCloseDelete}
          className=" border-0 pb-0"
        ></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <h2 className="text-warning">
              <PiSealWarning size={70} />
            </h2>
            <h4 className="font-18">Limit reached !</h4>
          </div>
        </Modal.Body>
        <Modal.Footer className=" justify-content-center border-0 pt-0"></Modal.Footer>
      </Modal>
      <DeleteModal
        action={action}
        show={showDelete}
        onDelete={(deleted: boolean) => deleteList(deleted)}
        deleteMsg={"Are you sure you want to delete?"}
        btn={"YES/NO"}
        deleteMethod="PUT"
      />
    </>
  );
};
export default TabUsers;








