import { Col, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Label } from "../../../Common/Label/Label";
import WebService from "../../../../Services/WebService";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

interface propsData {
  client: any;
}
const TabEncryption = (props: propsData) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    clearErrors
  } = useForm();
  let watchVariable = watch();

  useEffect(() => {
    if (props.client) {
      watchVariable.encryption = props.client.encryption;
      setValue("encryption", props.client.encryption);
    }
  }, [props.client]);
  const handleUpdateEncryption = () => {
    watchVariable.id = props.client.id;
    WebService.addLoader("save111");
    return WebService.putAPI({
      action: `client/encryption`,
      body: watchVariable,
      isShowError: true,
    })
      .then((res: any) => {
        toast.success(res.message);
        WebService.removeLoader("save111");
      })
      .catch((error: any) => {
        toast.error(error.message);
        WebService.removeLoader("save111");
        return error;
      });
  };
  const handleError = () => { };
  return (
    <>
      <Form
        className="form-style normal"
        name="Verify"
        id="Verify"
        onSubmit={handleSubmit(handleUpdateEncryption, handleError)}
      >
        <label className="mb-2">Encryption text for all users</label>
        <Col lg={6} className="mb-2">
          <textarea
            {...register("encryption", { required: true })}
            rows={10}
            className="h-100 form-control"
            cols={60}
            value={watchVariable.encryption}
            placeholder="Enter text"
            onChange={(e: any) => {
              clearErrors("encryption");
              watchVariable.encryption = e.target.value;
              setValue("encryption", e.target.value);
            }}
          ></textarea>
          {errors.encryption && (
            <Label
              title={t("add_client.enter_encrptionText")}
              modeError={true}
              showStar={true}
              type=""
              classNames="mb-2"
            />
          )}
        </Col>
        <Col lg={6}>
          <div className="text-center">
            <Button className="btn-brand-1" type="submit" id="save111">
              Save
            </Button>
          </div>
        </Col>
      </Form>
    </>
  );
};
export default TabEncryption;
