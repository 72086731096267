import {
  Button,
  FloatingLabel,
  Form,
  Offcanvas,
  Col,
  Row,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import WebService from "../../../../Services/WebService";
import { useEffect, useState } from "react";
import { Label } from "../../../Common/Label/Label";
import { toast } from "react-toastify";
import HelperService from "../../../../Services/HelperService";
import "./user.scss";
import Password from "../../../Common/Password/Password";
import { useSelector } from "react-redux";
interface PropsData {
  id: any;
  closeCanvasFlag: any;
  clientId: any;
  role?: any;
}

const AddUser = (props: PropsData) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
    setError,
    watch,
  } = useForm();
  let watchVariable = watch();
  const {
    register: register1,
    handleSubmit: handleSubmit1,
    setValue: setValue1,
    formState: { errors: errors1 },
    clearErrors: clearErrors1,
    setError: setError1,
    watch: watch1,
  } = useForm();

  let watchVariable1 = watch1();
  const { t } = useTranslation();
  const [domainFlag, setDomainflag] = useState(false);
  const [reportToflag, setReportToFlag] = useState(false);
  const [roleFlag, setRoleFlag] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState({
    value: "select_domain",
    label: "Select domain",
  });
  const [selectedReportTo, setSeletedReportTo] = useState({
    value: "select_reportTo",
    label: "Select reporting person",
  });
  const [selectedRole, setSelectedRole] = useState({
    value: "select_role",
    label: "Select role",
  });
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [email, setEmail] = useState("");
  const handleCloseChangePassword = () => {
    setShowChangePassword(false);
  };
  useEffect(() => {
    watchVariable.review_logs = false;
    setValue("review_logs", false);
    watchVariable.add_edit_user = false;
    setValue("add_edit_user", false);
    watchVariable.add_edit_trigers = false;
    setValue("add_edit_trigers", false);
    watchVariable.monthly_review_reminders = false;
    setValue("monthly_review_reminders", false);
    watchVariable.new_outgoing_email_triggered = false;
    setValue("new_outgoing_email_triggered", false);
    watchVariable.triggered_email_bypassed = false;
    setValue("triggered_email_bypassed", false);
    // watchVariable.connector_notification = false;
    // setValue("connector_notification", false);
    if (props.id !== "") {
      getUserById(props.id);
    }
  }, [props.id]);
  const getUserById = (id: any) => {
    return WebService.getAPI({
      action: `client/user?user_id=` + id + `&client_id=` + props.clientId,
      body: null,
      isShowError: true,
    })
      .then((res: any) => {
        if (res != null) {
          watchVariable.first_name = res.first_name;
          setValue("first_name", res.first_name);
          watchVariable.last_name = res.last_name;
          setValue("last_name", res.last_name);
          setEmail(res.email);
          setValue("email", res.email);
          if (res.role_type === "COMPLAINCE_OFFICER") {
            setEmail(res.email);
            watchVariable.email = res.email;
            setValue("email", res.email);
          } else {
            let emailResponse = res.email;
            let arr = emailResponse.split("@");
            if (arr.length === 2) {
              watchVariable.email1 = arr[0];
              setValue("email1", arr[0]);
              let obj = { label: arr[1], value: "" };
              setSelectedDomain(obj);
              setValue("domain", obj.label);
            }
            if (res.report_to) {
              let obj1 = {
                label: res.report_to.firstName + " " + res.report_to.lastName,
                value: res.report_to.id,
              };
              setSeletedReportTo(obj1);
              watchVariable.report_to = obj1.value;
              setValue("report_to", obj1.value);
            }
          }

          if (res.role) {
            let obj2 = {
              label: res.role.role === "COMPLIANCE_OFFICER" ? "Compliance" : HelperService.removeUnderScore(res.role.role),
              value: res.role.id,
            };
            watchVariable.role_id = obj2.value;
            setValue("role_id", obj2.value);
            setSelectedRole(obj2);
          }

          watchVariable.review_logs = res.review_logs;
          setValue("review_logs", res.review_logs);
          watchVariable.add_edit_trigers = res.add_edit_trigers;
          setValue("add_edit_trigers", res.add_edit_trigers);
          watchVariable.new_outgoing_email_triggered =
            res.new_outgoing_email_triggered;
          setValue(
            "new_outgoing_email_triggered",
            res.new_outgoing_email_triggered
          );
          watchVariable.monthly_review_reminders = res.monthly_review_reminders;
          setValue("monthly_review_reminders", res.monthly_review_reminders);
          watchVariable.triggered_email_bypassed = res.triggered_email_bypassed;
          setValue("triggered_email_bypassed", res.triggered_email_bypassed);
          watchVariable.add_edit_user = res.add_edit_user;
          setValue("add_edit_user", res.add_edit_user);
          // watchVariable.connector_notification = res.connector_notification;
          // setValue("connector_notification", res.connector_notification);



        }
      })
      .catch((error: any) => {
        toast.error(error.message);
        return error;
      });
  };
  const handleSaveUser = () => {
    if (selectedRole.label !== "Compliance") {
      if (watchVariable.domain === "" || watchVariable.domain === undefined) {
        setDomainflag(true);
        return;
      } else {
        setDomainflag(false);
      }
    }

    if (watchVariable.role_id === "" || watchVariable.role_id === undefined) {
      setRoleFlag(true);
      return;
    }
    if (
      (watchVariable.report_to == "" && selectedRole.label === "Basic user") ||
      (watchVariable.report_to === undefined &&
        selectedRole.label === "Basic user")
    ) {
      setReportToFlag(true);
      return;
    } else {
      setReportToFlag(false);
    }
    if (selectedRole.label !== "Compliance") {
      watchVariable.email = watchVariable.email1 + "@" + watchVariable.domain;
    }

    delete watchVariable.email1;
    delete watchVariable.domain;
    watchVariable.client_id = props.clientId;


    WebService.addLoader("save11");
    if (props.id == "") {
      return WebService.postAPI({
        action: `client/user`,
        body: watchVariable,
        isShowError: true,
      })
        .then((res: any) => {
          toast.success(res.message);
          WebService.removeLoader("save11");
          props.closeCanvasFlag(true);
        })
        .catch((error: any) => {
          toast.error(error.message);
          WebService.removeLoader("save11");
          return error;
        });
    } else {
      watchVariable.id = props.id;
      // delete watchVariable.role_id;

      return WebService.putAPI({
        action: `client/user`,
        body: watchVariable,
        isShowError: true,
      })
        .then((res: any) => {
          toast.success(res.message);
          WebService.removeLoader("save11");
          props.closeCanvasFlag(true);
        })
        .catch((error: any) => {
          toast.error(error.message);
          WebService.removeLoader("save11");
          return error;
        });
    }
  };
  const handleChangePassword = () => {
    if (watchVariable1.newPassword !== watchVariable1.cpassword) {
      setError1("cpassword", {
        type: "custom",
        message: "Those passwords didn’t match. Try again.",
      });
      return;
    }

    let obj = {
      password: watchVariable1.newPassword,
      email: email,
    };
    WebService.addLoader("save1");
    return WebService.putAPI({
      action: `change/user-password`,
      body: obj,
      isShowError: true,
    })
      .then((res: any) => {
        WebService.removeLoader("save1");
        toast.success(res.message);

        handleCloseChangePassword();
      })
      .catch((error: any) => {
        WebService.removeLoader("save1");
        return error;
      });
  };
  const handleError1 = (err: any) => {
   
  };
  const handleError = (error: any) => {   
    if (watchVariable.domain === "" || watchVariable.domain === undefined) {
      setDomainflag(true);
    } else {
      setDomainflag(false);
    }
    if (
      watchVariable.report_to === "" ||
      watchVariable.report_to === undefined
    ) {
      setReportToFlag(true);
    } else {
      setReportToFlag(false);
    }
    if (watchVariable.role_id === "" || watchVariable.role_id === undefined) {
      setRoleFlag(true);
    } else {
      setRoleFlag(false);
    }
  };
  const handleOnChange = (value: any, type: any) => {
    if (type === "first_name") {
      watchVariable.first_name = value;
      setValue("first_name", value);
      clearErrors("first_name");
      if (watchVariable.first_name === "") {
        setError("first_name", {
          type: "custom",
          message: "",
        });
      }
    } else if (type === "last_name") {
      watchVariable.last_name = value;
      setValue("last_name", value);
      clearErrors("last_name");
      if (watchVariable.last_name === "") {
        setError("last_name", {
          type: "custom",
          message: "",
        });
      }
    } else if (type === "email1") {
      watchVariable.email1 = value;
      setValue("email1", value);
      clearErrors("email1");
      if (watchVariable.email1 === "") {
        setError("email1", {
          type: "custom",
          message: "",
        });
      }
    } else if (type === "email") {
      watchVariable.email = value;
      setValue("email", value);
      clearErrors("email");
      if (watchVariable.email === "") {
        setError("email", {
          type: "custom",
          message: "",
        });
      }
    }
  };
  const onDomainSelection = (selectedOption: any) => {
    setDomainflag(false);
    watchVariable.domain = selectedOption.label;
    setValue("domain", selectedOption.label);
    setSelectedDomain(selectedOption);
  };
  const onReportToSelection = (selectedOption: any) => {
    setSeletedReportTo(selectedOption);
    watchVariable.report_to = selectedOption.value;
    setValue("report_to", selectedOption.value);
  };
  const onRoleSelection = (selectedOption: any) => {
    setSelectedRole(selectedOption);
    watchVariable.role_id = selectedOption.value;
    setValue("role_id", selectedOption.value);
  };
  const domainPromiseOption = (inputValue: any) =>
    new Promise<any[]>((resolve) => {
      return WebService.getAPI({
        action: `domain/list?client_id=` + props.clientId,
        body: null,
      })
        .then((res: any) => {
          if (res.length > 0) {
            let updatedOptions = res.map((domain: any, index: any) => ({
              value: index + 1,
              label: domain.name,
            }));
            if (res.length === 1) {
              setSelectedDomain(updatedOptions);
              setValue("domain", updatedOptions[0].label);
            }
            resolve(updatedOptions);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          resolve([]);
          return error;
        });
    });
  const rolePromiseOption = (inputValue: any) =>
    new Promise<any[]>((resolve) => {
      return WebService.getAPI({
        action: `role/list`,
      })
        .then((res: any) => {
          let updatedOptions
          if (res.length > 0) {
            if (props.id && props.role !== "COMPLIANCE_OFFICER") {
              updatedOptions = res.filter((ele: any) => ele.role !== "COMPLIANCE_OFFICER").map((role: any) => ({
                value: role.id,
                label: role.role === "COMPLIANCE_OFFICER" ? "Compliance" : HelperService.removeUnderScore(role.role),
              }));
            } else {
              updatedOptions = res.map((role: any) => ({
                value: role.id,
                label: role.role === "COMPLIANCE_OFFICER" ? "Compliance" : HelperService.removeUnderScore(role.role),
              }))
            }
            resolve(updatedOptions);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          resolve([]);
          return error;
        });
    });
  const ReportToPromiseOption = (inputValue: any) =>
    new Promise<any[]>((resolve) => {
      return WebService.getAPI({
        action: `report/list?client_id=` + props.clientId,
        body: null,
      })
        .then((res: any) => {
          if (res.length > 0) {
            let updatedOptions = res.filter((ele: any) => ele.id !== props.id).map((reportTo: any, index: any) => ({
              value: reportTo.id,
              label: reportTo.first_name + " " + reportTo.last_name,
            }));
            resolve(updatedOptions);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          resolve([]);
          return error;
        });
    });
  const handlePermisionSelection = (value: any, type: any) => {
    if (type === "review_logs") {
      watchVariable.review_logs = value;
      setValue("review_logs", value);
    } else if (type === "add_edit_user") {
      watchVariable.add_edit_user = value;
      setValue("add_edit_user", value);
    } else if (type === "add_edit_trigers") {
      watchVariable.add_edit_trigers = value;
      setValue("add_edit_trigers", value);
    } else if (type === "monthly_review_reminders") {
      watchVariable.monthly_review_reminders = value;
      setValue("monthly_review_reminders", value);
    } else if (type === "new_outgoing_email_triggered") {
      watchVariable.new_outgoing_email_triggered = value;
      setValue("new_outgoing_email_triggered", value);
    } else if (type === "triggered_email_bypassed") {
      watchVariable.triggered_email_bypassed = value;
      setValue("triggered_email_bypassed", value);
    }
    // else if (type === "connector_notification") {
    //   watchVariable.connector_notification = value;
    //   setValue("connector_notification", value);
    // }



  };
  const handleOnChange1 = (value: any, type: any) => {
    if (type === "newPassword") {
      setValue1("newPassword", value);
      clearErrors1("newPassword");
      if (watchVariable1.newPassword === "") {
        setError1("newPassword", {
          type: "custom",
          message: "Please enter new password",
        });
      }
    } else if (type === "cpassword") {
      setValue1("cpassword", value);
      clearErrors1("cpassword");
      if (watchVariable1.cpassword === "") {
        setError1("cpassword", {
          type: "custom",
          message: "Please enter confirm password",
        });
      }
    }
  };
  return (
    <>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          {props.id === "" ? "Add New User" : "Edit User"}
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form
          className="w-100 form-style"
          name="Verify"
          id="Verify"
          onSubmit={handleSubmit(handleSaveUser, handleError1)}
        >
          <Row>
            <Col lg={6}>
              <FloatingLabel
                controlId="FirstName"
                label="First name"
                className="mb-3"
              >
                <input
                  {...register("first_name", {
                    required: true,
                  })}
                  type="text"
                  className="form-control ps-0 "
                  placeholder={t("Organization.first_name")}
                  onChange={(e) => {
                    handleOnChange(e.target.value, "first_name");
                  }}
                />
                {errors.first_name && (
                  <Label
                    title="Please enter first name"
                    modeError={true}
                    showStar={true}
                    type=""
                  />
                )}
              </FloatingLabel>
            </Col>
            <Col lg={6}>
              <FloatingLabel
                controlId="LastName"
                label="Last name"
                className="mb-3"
              >
                <input
                  {...register("last_name", {
                    required: true,
                  })}
                  type="text"
                  className="form-control ps-0"
                  placeholder={t("Organization.last_name")}
                  onChange={(e) => {
                    handleOnChange(e.target.value, "last_name");
                  }}
                />
                {errors.last_name && (
                  <Label
                    title="Please enter last name"
                    modeError={true}
                    classNames="ps-2"
                    showStar={true}
                    type=""
                  />
                )}
              </FloatingLabel>
            </Col>
          </Row>

          <Row>
            <Col lg={12} className="mb-3">
              <AsyncSelect
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                {...register("role_id")}
                cacheOptions
                defaultOptions
                onChange={onRoleSelection}
                loadOptions={rolePromiseOption}
                value={selectedRole}
                isDisabled={props.role === "COMPLIANCE_OFFICER" ? true : false}
              />
              {(watchVariable.role_id === undefined ||
                watchVariable.role_id === "") &&
                roleFlag ? (
                <Label title="Please select role" modeError={true} />
              ) : (
                ""
              )}
            </Col>
          </Row>
          {selectedRole.label === "Compliance" ? (
            <Row className=" align-items-center">
              <Col lg={12}>
                <FloatingLabel controlId="Email" label="Email" className="mb-3">
                  <input
                    {...register("email", {
                      required: "Please enter email",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,60}$/i,
                        message: "Invalid email format",
                      },
                    })}
                    type="text"
                    className="form-control ps-0"
                    placeholder="Email"
                    onChange={(e) => {
                      handleOnChange(e.target.value, "email");
                    }}
                    defaultValue={email}
                  />
                  {errors.email && (
                    <Label
                      title={errors.email.message?.toString()}
                      modeError={true}
                      showStar={true}
                      type=""
                    />
                  )}
                </FloatingLabel>
              </Col>
            </Row>
          ) : (
            <Row className=" align-items-center">
              <Col>
                <FloatingLabel controlId="Email" label="Email" className="mb-3">
                  <input
                    {...register("email1", {
                      required: "Please enter email",
                      pattern: {
                        value: /^[a-zA-Z0-9._%+-]+$/,
                        message: "Invalid email format",
                      },
                    })}
                    type="text"
                    className="form-control ps-0"
                    placeholder={t("Organization.email")}
                    onChange={(e) => {
                      handleOnChange(e.target.value, "email1");
                    }}
                  />
                  {errors.email1 && (
                    <Label
                      title={errors.email1.message?.toString()}
                      modeError={true}
                      showStar={true}
                      type=""
                    />
                  )}
                </FloatingLabel>
              </Col>
              <Col className="mb-3 p-0" style={{ maxWidth: "10px" }}>
                {" "}
                <span>@</span>
              </Col>
              <Col className="mb-3">
                <div>
                  <AsyncSelect
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    {...register("domain", { required: true })}
                    cacheOptions
                    defaultOptions
                    onChange={onDomainSelection}
                    loadOptions={domainPromiseOption}
                    value={selectedDomain}
                  />

                  {(watchVariable.domain === undefined ||
                    watchVariable.domain === "") &&
                    domainFlag ? (
                    <Label title="Please select domain" modeError={true} />
                  ) : (
                    ""
                  )}
                </div>
              </Col>
            </Row>
          )}

          {selectedRole.label === "Basic user" ? (
            <Row>
              <Col lg={12} className="mb-3">
                <AsyncSelect
                  {...register("report_to")}
                  cacheOptions
                  defaultOptions
                  onChange={onReportToSelection}
                  loadOptions={ReportToPromiseOption}
                  value={selectedReportTo}
                />
                {(watchVariable.report_to === undefined ||
                  watchVariable.report_to === "") &&
                  reportToflag ? (
                  <Label
                    title="Please select reporting user"
                    modeError={true}
                  />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          ) : (
            ""
          )}
          {selectedRole.label === "Compliance" ? (
            <div className="row mb-3">
              <Col lg={6} className="mb-2">
                <label className="font-1 font-medium mb-2 font-18">
                  Organization permissions
                </label>
                <Form.Check
                  {...register("review_logs")}
                  type="checkbox"
                  className="mb-3"
                  id="Canreviewlogs"
                  label="Can review logs"
                  onChange={() => {
                    handlePermisionSelection(
                      !watchVariable.review_logs,
                      "review_logs"
                    );
                  }}
                />
                <Form.Check
                  {...register("add_edit_user")}
                  type="checkbox"
                  className="mb-3"
                  id="Canadd/editusers"
                  label="Can add/edit users"
                  onChange={() => {
                    handlePermisionSelection(
                      !watchVariable.add_edit_user,
                      "add_edit_user"
                    );
                  }}
                />
                <Form.Check
                  {...register("add_edit_trigers")}
                  type="checkbox"
                  className="mb-3"
                  id="Canadd/edittriggers"
                  label="Can add/edit triggers"
                  onChange={() => {
                    handlePermisionSelection(
                      !watchVariable.add_edit_trigers,
                      "add_edit_trigers"
                    );
                  }}
                />
              </Col>
              <Col lg={6} className="mb-2">
                <label className="font-1 font-medium mb-2 font-18">
                  Email notifications
                </label>
                <Form.Check
                  {...register("monthly_review_reminders")}
                  type="checkbox"
                  className="mb-3"
                  id="Monthlyreviewreminders"
                  label="Monthly review reminders"
                  onChange={() => {
                    handlePermisionSelection(
                      !watchVariable.monthly_review_reminders,
                      "monthly_review_reminders"
                    );
                  }}
                />
                <Form.Check
                  {...register("new_outgoing_email_triggered")}
                  type="checkbox"
                  className="mb-3"
                  id="Newoutgoingemail"
                  label="Monthly flag reports"
                  onChange={() => {
                    handlePermisionSelection(
                      !watchVariable.new_outgoing_email_triggered,
                      "new_outgoing_email_triggered"
                    );

                  }}
                />

              </Col>
            </div>
          ) : (
            ""
          )}

          {selectedRole.label === "Account admin" ? (
            <div className="row mb-3">
              <Col lg={6} className="mb-2">
                <label className="font-1 font-medium mb-2 font-18">
                  Email notifications
                </label>
                <Form.Check
                  {...register("monthly_review_reminders")}
                  type="checkbox"
                  className="mb-3"
                  id="Monthlyreviewreminders"
                  label="Monthly review reminders"
                  onChange={() => {
                    handlePermisionSelection(
                      !watchVariable.monthly_review_reminders,
                      "monthly_review_reminders"
                    );
                  }}
                />
                <Form.Check
                  {...register("new_outgoing_email_triggered")}
                  type="checkbox"
                  className="mb-3"
                  id="Newoutgoingemail"
                  label="Monthly flag reports"
                  onChange={() => {
                    handlePermisionSelection(
                      !watchVariable.new_outgoing_email_triggered,
                      "new_outgoing_email_triggered"
                    );
                  }}
                />
                {/* <Form.Check
                  {...register("connector_notification")}
                  type="checkbox"
                  className="mb-3"
                  id="connectorNotification"
                  label="Connector notification"
                  onChange={() => {
                    handlePermisionSelection(
                      !watchVariable.connector_notification,
                      "connector_notification"
                    );
                  }}
                /> */}
              </Col>
            </div>
          ) : (
            ""
          )}

          <div>
            <Button
              type="submit"
              className="btn btn-brand-1 w-100 mb-2"
              id="save11"
            >
              Save
            </Button>
            <span className="mt-2 text-danger font-12">Note: Compliance users cannot add any connectors or view billing.</span>
          </div>
        </Form>
        {props.id === "" ? "" :
          <Col className="item-align-end font-12" style={{ textAlign: "end" }}>
            <>
              {/* <RxDownload size={16} */}
              <a
                onClick={() => {
                  setShowChangePassword(true);
                }}
                className="font-14 cursor-pointer"
              >
                Change Password
              </a>
            </>
          </Col>
        }
      </Offcanvas.Body>

      <Offcanvas
        show={showChangePassword}
        onHide={handleCloseChangePassword}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Change Password</Offcanvas.Title>
        </Offcanvas.Header>
        <Form
          className="w-100"
          name="Verify"
          id="Verify"
          onSubmit={handleSubmit1(handleChangePassword, handleError)}
        >
          <Offcanvas.Body className="form-style">
            <div className="px-3 pt-3">
              <div className="input-group mb-2 mt-1">
                <Password
                  requiredMsg={{
                    ...register1("newPassword", {
                      required: "Please enter new password",
                      pattern: {
                        value:
                          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!*-])(?=.*[a-zA-Z@#$%^&+=!*-]).{6,}$/,
                        message:
                          // "Passwords should contain a combination of six character types[Min 1 uppercase letter + Min 1 lowercase letter + Min 1 number + Min 1 Special character allowed (@ # $ % ^ & + = !)]",
                          "Please enter valid password that satisfy below criteria"
                      },
                    }),
                  }}
                  inputFieldClassName="form-control border-end-0 border-start-0 ps-0"
                  id=""
                  name=""
                  label="Password"
                  placeholder="New Password"
                  method={(event: any) => {
                    handleOnChange1(event.target.value, "newPassword");
                  }}
                  onError={
                    errors1.newPassword ? (
                      <div className="mb-2">
                        <Label
                          title={
                            errors1.newPassword.message
                              ? errors1.newPassword.message.toString()
                              : ""
                          }
                          modeError={true}
                          showStar={true}
                          type="password"
                        />
                      </div>
                    ) : (
                      ""
                    )
                  }
                />{" "}
              </div>
              <div className="input-group mb-2 mt-0">
                <Password
                  requiredMsg={{
                    ...register1("cpassword", {
                      required: "Please enter confirm password",
                    }),
                  }}
                  inputFieldClassName="form-control border-end-0 border-start-0 ps-0"
                  id=""
                  name=""
                  label="Password"
                  placeholder="Confirm Password"
                  method={(event: any) => {
                    handleOnChange1(event.target.value, "cpassword");
                  }}
                  onError={
                    errors1.cpassword ? (
                      <Label
                        title={
                          errors1.cpassword.message
                            ? errors1.cpassword.message.toString()
                            : ""
                        }
                        modeError={true}
                        showStar={true}
                        type="password"
                      />
                    ) : (
                      ""
                    )
                  }
                />
              </div>
            </div>
            {
              <div className="font-12 text-danger px-3 mb-3">
                Your password must meet the following criteria:<br></br>
                <div className="px-3">
                  - Minimum length of 6 characters.<br></br>
                  - At least 1 uppercase letter.<br></br>
                  - At least 1 lowercase letter.<br></br>
                  - At least 1 number.<br></br>
                  - Special characters allowed: ! @ # $ % ^ & * - + =<br></br>
                </div>
              </div>
            }

            <div className="px-3">
              <Button className="btn-brand-1 w-100" type="submit" id="save1">
                Save
              </Button>
            </div>
          </Offcanvas.Body>
        </Form>
      </Offcanvas>
    </>
  );
};
export default AddUser;
