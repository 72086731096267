import { Link, useLocation } from 'react-router-dom';
import icon3 from '../../assets/images/menu-icon-3.svg'
import icon4 from '../../assets/images/menu-icon-4.svg'
import iconSubscription from "../../assets/images/icon-subscription.svg"
import iconDashboard from "../../assets/images/icon-dashboard.svg"
import iconComplienceUser from "../../assets/images/icon-compli-user.svg"
import iconImessage from "../../assets/images/icon-monitoring.svg"
import iconReport from "../../assets/images/reportIcon.svg";
import iconWhatsapp from "../../assets/images/icon-whatsapp.svg";
import iconKeyword from "../../assets/images/AI Keyboard.svg";
import iconManageAdmin from "../../assets/images/mange-admin.svg";
import { useEffect } from 'react';
import iconkeyword from "../../assets/images/AI Keyboard.svg"
import { useSelector } from 'react-redux';





const VerticalMenu = () => {
    // const userRole = useSelector((state: any) => state.profile.role ?? "");
    const userRole = localStorage.getItem("role");
    const location = useLocation();
    const isActive = (path: any) => location.pathname === path;
    const loginUserRoleType = useSelector((state: any) => state.profile.loginUserRoleType ?? "");
    useEffect(() => { }, [])
    return (
        <>
            <div id="vertical_menu" className="verticle-menu px-2">
                <div className="menu-list">
                    {
                        loginUserRoleType === "SYSTEM_ADMIN" ?
                            <>
                                <Link id="t-1" to={'/dashboard'} className="menu-item" style={{ backgroundColor: isActive('/dashboard') ? '#f5f5f5' : 'transparent' }}> <img src={iconDashboard} alt="icon" className="menu-icon" /> <span className='nav-text'>Dashboard</span></Link>
                                <Link id="t-1" to={'/manage-clients'} className="menu-item" style={{ backgroundColor: isActive('/manage-clients') ? '#f5f5f5' : 'transparent' }}> <img src={icon4} alt="icon" className="menu-icon" /> <span className='nav-text'>Manage Clients</span></Link>
                                <Link id="t-1" to={'/manage-admin'} className="menu-item" style={{ backgroundColor: isActive('/manage-admin') ? '#f5f5f5' : 'transparent' }}> <img src={iconManageAdmin} alt="icon" className="menu-icon" /> <span className='nav-text'>Manage Admin</span></Link>
                                <Link id="t-1" to={'/integration'} className="menu-item" style={{ backgroundColor: isActive('/integration') ? '#f5f5f5' : 'transparent' }}> <img src={icon3} alt="icon" className="menu-icon" /> <span className='nav-text'>Connectors</span></Link>
                                <Link id="t-1" to={'/compliance-user'} className="menu-item" style={{ backgroundColor: isActive('/compliance-user') ? '#f5f5f5' : 'transparent' }}> <img src={iconComplienceUser} alt="icon" className="menu-icon" /> <span className='nav-text'>Compliance Officer</span></Link>
                                <Link id="t-1" to={'/subscription'} className="menu-item" style={{ backgroundColor: isActive('/subscription') ? '#f5f5f5' : 'transparent' }}> <img src={iconSubscription} alt="icon" className="menu-icon" /> <span className='nav-text'>Subscription</span></Link>
                                <Link id="t-1" to={'/imessage'} className="menu-item" style={{ backgroundColor: isActive('/imessage') ? '#f5f5f5' : 'transparent' }} > <img src={iconImessage} alt="icon" className="menu-icon" /> <span className='nav-text'>iMessage</span></Link>
                                <Link id="t-1" to={'/whatsapp'} className="menu-item" style={{ backgroundColor: isActive('/whatsapp') ? '#f5f5f5' : 'transparent' }}> <img src={iconWhatsapp} alt="icon" className="menu-icon" /> <span className='nav-text'>WhatsApp</span></Link>
                                <Link id="t-1" to={'/connectors-report'} className="menu-item" style={{ backgroundColor: isActive('/connectors-report') ? '#f5f5f5' : 'transparent' }}> <img src={iconReport} alt="icon" className="menu-icon" /> <span className='nav-text'>Connectors Report</span></Link>
                                <Link id="t-1" to={'/keywords'} className="menu-item" style={{ backgroundColor: isActive('/keywords') ? '#f5f5f5' : 'transparent' }}> <img src={iconKeyword} alt="icon" className="menu-icon" /> <span className='nav-text'>AI Feedback</span></Link>
                            </>
                            :
                            loginUserRoleType === "ACCOUNT_ADMIN" ?
                                <>
                                    <Link id="t-1" to={'/clientadmin-overview'} className="menu-item" style={{ backgroundColor: isActive('/clientadmin-overview') ? '#f5f5f5' : 'transparent' }}> <img src={iconDashboard} alt="icon" className="menu-icon" /> <span className='nav-text'>Overview</span></Link>
                                    <Link id="t-1" to={'/manage-clientadmin'} className="menu-item" style={{ backgroundColor: isActive('/manage-clientadmin') ? '#f5f5f5' : 'transparent' }}> <img src={icon4} alt="icon" className="menu-icon" /> <span className='nav-text'>Manage Clients</span></Link>
                                    <Link id="t-1" to={'/clientadmin-connector-report'} className="menu-item" style={{ backgroundColor: isActive('/clientadmin-connector-report') ? '#f5f5f5' : 'transparent' }}> <img src={iconReport} alt="icon" className="menu-icon" /> <span className='nav-text'>Connector Report</span></Link>
                                </>
                                :
                                loginUserRoleType === "ADMIN_COMPLIANCE_USER" ?
                                    <Link id="t-1" to={'/keywords'} className="menu-item" style={{ backgroundColor: isActive('/keywords') ? '#f5f5f5' : 'transparent' }}> <img src={iconKeyword} alt="icon" className="menu-icon" /> <span className='nav-text'>AI Feedback</span></Link>
                                    : ""
                    }
                </div>
                <div className="version-badge" style={{ position: "absolute", bottom: "10px", width: "80%" }}>
                    V-1.0.9
                </div>
            </div>


        </>
    )
}
export default VerticalMenu;



