import { useEffect, useState } from "react";
import {
    Row,
    Col,
    Button,
    Card,
    Offcanvas,
    Form,
    InputGroup,
    Table,
    FloatingLabel,
} from "react-bootstrap";
import { PiTrash, PiUploadSimple } from "react-icons/pi";
import NoDataFound from "../../../../assets/images/empty_item.svg";
import { useTranslation } from "react-i18next";
import WebService from "../../../../Services/WebService";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import DeleteModal from "../../../Common/DeleteModal/DeleteModal";
import { IoSearchOutline } from "react-icons/io5";
import {
    MdOutlineKeyboardArrowDown,
    MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import TableLoader from "../../../Common/TableLoader/TableLoader";
import AIPagination from "../../../Common/Pagination/AIPagination";
import { TbCloudDownload } from "react-icons/tb";
import { Label } from "../../../Common/Label/Label";
import { useSelector } from "react-redux";

interface PropsData {
    client: any;
    tab: any;
}
const TabDomain = (props: PropsData) => {
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        clearErrors,
        setError,
        watch,
        reset,
        control
    } = useForm();
    let watchVariable = watch();


    const {
        register: register1,
        handleSubmit: handleSubmit1,
        setValue: setValue1,
        formState: { errors: errors1 },
        clearErrors: clearErrors1,
        setError: setError1,
        watch: watch1,
    } = useForm();
    let watchVariable1 = watch1();

    const [showAddBlade, setShowAddBlade] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [clientId, setClientId] = useState("");
    const handleCloseAddBlade = () => {
        setShowAddBlade(false);
        setId("");
    };
    const handleShowAddBlade = () => {
        setShowAddBlade(true);
        reset();

    };
    const [showImport, setShowImport] = useState<any>(false);
    const [loader, setLoader] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [domainList, setDomainList] = useState<any[]>([]);
    const [sortBy, setSortBy] = useState("");
    const [orderBy, setOrderBy] = useState("DESC");
    const [page, setPage] = useState(1);
    const [action, setAction] = useState("");
    const [id, setId] = useState("");
    const [keyword, setKeyword] = useState("");
    const [clickable, setClickable] = useState("domain_name");
    const [files, setFile] = useState<File | null>();
    const [fileErrorMsg, setFileErrorMsg] = useState("")
    const [errorFlag, setErrorFlag] = useState(false);
    const [domains, setDomains] = useState<any[]>([]);

    useEffect(() => {
        if (props.client) {
            getDomainList(props.client.id);
            setClientId(props.client.id);
        }
    }, [sortBy, orderBy, page, props.client, keyword]);


    const getDomainList = (clientId: any) => {
        setLoader(true);
        const obj = {
            page: keyword !== "" ? "1" : page.toString(),
            sort_by: sortBy,
            order_by: orderBy,
            id: clientId,
            keyword: keyword,
        };
        let qurey = new URLSearchParams(obj).toString();
        return WebService.postAPI({
            action: `client/domain-list?` + qurey,
            body: null,
            isShowError: true,
        })
            .then((res: any) => {
                setDomainList(res.list);
                setTotalCount(res.count);
                setLoader(false);
            })
            .catch((error: any) => {
                toast.error(error.message);
                setLoader(false);
                return error;
            });
    };

    const deleteList = (isDelete: any) => {
        if (isDelete) {
            getDomainList(props.client.id);
        }
        setShowDelete(false);
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
            setValue1("fileImport", e.target.files[0]);
            setErrorFlag(false);
            setFileErrorMsg("");
        }
    };

    const handleUploadClick = () => {
        if (!files) {
            setErrorFlag(true);
            setFileErrorMsg("Please select csv file");
            return;
        }
        if (files?.size > 5368709120) {
            setErrorFlag(true);
            setFileErrorMsg("File size must be less than 5GB")
            return;
        }
        if (files?.name.split(".")[1] != "csv" && files?.name.split(".")[1] != "csv") {
            setErrorFlag(true);
            setFileErrorMsg("Please select csv file")
            return;
        }
        setErrorFlag(false)
        setFileErrorMsg("")
        WebService.addLoader("domain_import_btn");
        return WebService.uploadAPI({
            action: `import-domain?client_id=${props.client.id}`,
            body: null,
            _file: files,
            key: "file",
        })
            .then((res: any) => {
                toast.success(res.message);
                getDomainList(props.client.id);
                setShowImport(false);
                WebService.removeLoader("domain_import_btn");
            })
            .catch((error) => {
                if (error?.response?.data?.message) {
                    toast.error(error?.response?.data?.message);
                }
                setShowImport(false);
                WebService.removeLoader("domain_import_btn");
                return [];
            });
    };

    const handleAddDomain = () => {
        let body = {
            "id": props.client.id,
            "domains": domains.toString()
        }
        WebService.addLoader("domain_add_btn");
        return WebService.postAPI({
            action: `client-domain`,
            body: body,
            isShowError: true,
        })
            .then((res: any) => {
                toast.success(res.message);
                getDomainList(props.client.id);
                setShowAddBlade(false);
                WebService.removeLoader("domain_add_btn");
            })
            .catch((error: any) => {
                toast.error(error.message);
                WebService.removeLoader("domain_add_btn");
                return error;
            });
    }


    return (
        <>
            <Row className=" justify-content-end">
                <Col md={6} className="d-flex gap-3 justify-content-end mb-3">
                    <InputGroup className="table-search-box border">
                        <InputGroup.Text id="basic-addon1">
                            <IoSearchOutline className="icon" />
                        </InputGroup.Text>
                        <Form.Control
                            placeholder="Search for domain"
                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                if (e.key === "Enter") {
                                    setKeyword(e.currentTarget.value);
                                }
                            }}
                        />
                    </InputGroup>
                    <Button
                        className="btn-brand-1 text-nowrap"
                        onClick={handleShowAddBlade}
                    >
                        + Add
                    </Button>
                    <Button
                        className="btn-brand-light text-nowrap"
                        onClick={() => { setFile(null); setShowImport(true) }}
                    >
                        <TbCloudDownload size={18} className="me-1" />
                        Import
                    </Button>
                </Col>
            </Row>

            <div className="table-card">
                <Card className="table-container">
                    <Table className="table-style">
                        <thead>
                            <tr>
                                <th
                                    onClick={() => {
                                        setSortBy("domain_name");
                                        setClickable("domain_name");
                                    }}
                                >
                                    Domain
                                    {sortBy === "domain_name" &&
                                        orderBy === "DESC" &&
                                        clickable === "domain_name" ? (
                                        <MdOutlineKeyboardArrowDown
                                            className="sort-icon cursor-pointer"
                                            size={14}
                                            onClick={() => {
                                                setOrderBy("ASC");
                                                setSortBy("domain_name");
                                                setClickable("domain_name");
                                            }}
                                        />
                                    ) : clickable === "domain_name" ? (
                                        <MdOutlineKeyboardArrowUp
                                            className="sort-icon cursor-pointer"
                                            size={14}
                                            onClick={() => {
                                                setOrderBy("DESC");
                                                setSortBy("domain_name");
                                            }}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </th>
                                <th className="">{t("Common.action")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!loader ? (
                                domainList.length > 0 ? (
                                    domainList.map((domain: any, index: any) => {
                                        return (
                                            <tr>
                                                <td>{domain.name}</td>
                                                <td>
                                                    <div className="action-btns" style={{ display: 'flex', gap: '8px', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                        <Button
                                                            className="action-btn btn-delete"
                                                            title="Delete"
                                                            onClick={() => {
                                                                setAction(`client/domain-delete?client_id=${props.client.id}&domain_id=${domain.id}`);
                                                                setShowDelete(true);
                                                            }}
                                                        >
                                                            <PiTrash className="icon" />
                                                        </Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan={12}>
                                            <div className="table-wrap position-relative w-100">
                                                <div className="no-data-found w-100">
                                                    <div className="text-center">
                                                        <img
                                                            src={NoDataFound}
                                                            width={110}
                                                            className="mb-3"
                                                            alt={""}
                                                        />
                                                        <h3 className="text-secondary font-12">
                                                            {t("Common.No_Record_Found")}
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            ) : (
                                <tr>
                                    <td colSpan={6}>
                                        <TableLoader customClass={"remove-loader-height"} />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Card>

                <AIPagination
                    pagination={100}
                    totalItems={totalCount}
                    changePage={(page: number) => {
                        setPage(page);
                    }}
                    changeCurrentPage={page}
                />
            </div>
            <Offcanvas
                show={showAddBlade}
                onHide={() => setShowAddBlade(false)}
                placement="end"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Add Domain</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form className="form-style"
                        onSubmit={handleSubmit(handleAddDomain)}
                    >
                        <div className="mt-1 mb-3">
                            <Controller
                                name="domain"
                                control={control}
                                rules={{
                                    required: "Please enter domain",
                                    validate: (value: string) => {
                                        const domainArray = value?.split(',')?.map((domain) => domain?.trim());
                                        const domainRegex = /^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z0-9-]+\.[a-zA-Z0-9-]+|[a-zA-Z0-9-]+)$/i;
                                        if (domainArray?.filter((value: string) => value !== "")?.length > 0) {
                                            for (const domain of domainArray) {
                                                if (!domainRegex.test(domain)) {
                                                    return "Please enter a valid domain (e.g., gmail.com) or a comma-separated list of such domains (e.g., gmail.com, yahoo.co.in)";
                                                }
                                            }
                                            return true;
                                        }
                                    },
                                }}
                                render={({ field }) => (
                                    <FloatingLabel controlId="domain" label="Domain">
                                        <Form.Control
                                            type="text"
                                            onChange={(e: any) => {
                                                field.onChange(e);
                                                const values = e.target.value.split(",").map((value: any) => value.trim());
                                                setDomains(values);
                                            }}
                                            placeholder="Domain"
                                        // value={emailReceivers}
                                        />
                                    </FloatingLabel>
                                )}
                            />
                            {errors.domain && (
                                <Label
                                    title={errors.domain.message?.toString()}
                                    modeError={true}
                                    showStar={true}
                                    type=""
                                />
                            )}
                        </div>
                        <div>
                            <Button type="submit" id="domain_add_btn" className="btn btn-brand-1 w-100 mt-2">Save</Button>
                        </div>
                    </Form>
                </Offcanvas.Body >
            </Offcanvas>



            <Offcanvas show={showImport} onHide={() => setShowImport(false)} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Import</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form className="form-style"
                        onSubmit={handleSubmit1(handleUploadClick)}
                    >
                        <div className="upload-btn-container mb-2">
                            <label htmlFor="fileImport"><PiUploadSimple size={18} className="me-3" />{files ? files?.name : "Upload"}</label>
                            <input
                                {...register1("fileImport", { required: false })}
                                type="file"
                                id="fileImport"
                                accept=" "
                                onChange={handleFileChange}
                            />
                        </div>
                        <Row>
                            <Col className="font-12 text-secondary">
                                *You can upload csv file
                                {errorFlag ? (
                                    <Label title={fileErrorMsg} modeError={true} />
                                ) :
                                    ""
                                }
                            </Col>
                        </Row>
                        <div>
                            <Button type="submit" id="domain_import_btn" className="btn btn-brand-1 w-100 mt-2">Import</Button>
                        </div>
                        <div style={{ "textAlign": "end", marginBottom: "10px" }}>
                            <a
                                className="font-12"
                                style={{ color: "#0000FF", cursor: "pointer" }}
                                href={`${process.env.PUBLIC_URL}/import-domain-sample.csv`}
                                download="import-domain-sample.csv"
                            >
                                Download sample file
                            </a>
                        </div>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>


            <DeleteModal
                action={action}
                show={showDelete}
                onDelete={(deleted: boolean) => deleteList(deleted)}
                deleteMsg=""
                deleteMethod="DELETE"
                btn="YES/NO"
            />
        </>
    );
};
export default TabDomain;








